<template>
    <Modal
        ref="modal"
        fullWidth
        maxWidth="6xl"
        :buttons="['cancel', 'save', zone.id && 'delete']"
        @saved="handleSaved"
        @removed="handleRemove"
    >
        <template v-slot:title>{{ zone.id ? 'Modify Zone' : 'Create Zone' }}</template>
        <template v-slot:content>
            <div class="modal-inner">
                <div class="fields-wrapper">
                    <div class="top-fields">
                        <div class="field">
                            <label for="title" class="block text-sm font-medium text-gray-700">Zone Name</label>
                            <div class="mt-1 flex">
                                <input v-model="zone.title" type="text" autocomplete="off" />
                            </div>
                        </div>

                        <div class="row">
                            <div class="field">
                                <label for="pricePerPeriod" class="block text-sm font-medium text-gray-700">
                                    Price Per Period
                                </label>
                                <div class="mt-1">
                                    <input
                                        v-model="zone.pricePerPeriod"
                                        type="number"
                                        id="pricePerPeriod"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>

                            <div class="field">
                                <label for="taxRate" class="block text-sm font-medium text-gray-700"> Tax Rate </label>
                                <div class="mt-1">
                                    <input
                                        v-model="zone.taxRate"
                                        type="number"
                                        step="0.01"
                                        id="taxRate"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="field">
                                <label for="periodMinutes" class="block text-sm font-medium text-gray-700">
                                    Period Minutes
                                </label>
                                <div class="mt-1">
                                    <input
                                        v-model="zone.periodMinutes"
                                        type="number"
                                        id="periodMinutes"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>

                            <div class="field">
                                <label for="minimumPeriodCount" class="block text-sm font-medium text-gray-700">
                                    Minimum Periods
                                </label>
                                <div class="mt-1">
                                    <input
                                        v-model="zone.minimumPeriodCount"
                                        type="number"
                                        id="minimumPeriodCount"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="field">
                                <label for="isActive" class="block text-sm font-medium text-gray-700"> Active </label>
                                <div class="mt-1">
                                    <SwitchGroup as="div" class="switch-group">
                                        <Switch v-model="zone.isActive" :class="[zone.isActive && 'on', 'switch']">
                                            <span class="sr-only">Active</span>
                                            <span aria-hidden="true" :class="[zone.isActive && 'on', 'thumb']" />
                                        </Switch>
                                    </SwitchGroup>
                                </div>
                            </div>

                            <div class="field"></div>
                        </div>
                    </div>

                    <div class="drawing-tools">
                        <label class="block text-sm font-medium text-gray-700">Drawing Mode</label>
                        <Switcher v-model="drawMode" :options="['add', 'remove']" />

                        <label class="block text-sm font-medium text-gray-700">Quick {{ drawMode.ucfirst() }}</label>
                        <div class="field">
                            <smart-select
                                v-model="geoResolutionType"
                                :options="{ county: 'County', city: 'City', zip: 'ZIP' }"
                            />
                            <div class="field">
                                <input v-model="geoResolutionQuery" type="text" autocomplete="off" class="ml-1" />
                            </div>
                            <button @click="quickMutate"><i class="fa fa-search" /></button>
                        </div>
                    </div>
                </div>

                <Map
                    class="map"
                    ref="map"
                    drawable
                    :feature="zone.geoFeature"
                    :options="{ streetViewControl: false, controlSize: 24 }"
                    @ready="handleMapReady"
                    @drewFeature="handleDrawnFeature"
                />
            </div>
        </template>
    </Modal>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';

import Map from '@/common/components/c-map';
import Modal from '@/common/components/c-modal';
import Switcher from '@/common/components/c-switcher';
import { GeoJSONTools } from '@/common/libs/geojson-tools';
import { MapHelpers } from '@/common/libs/google-maps';

export default {
    components: {
        Modal,
        Map,
        Switcher,
        SwitchGroup,
        Switch
    },

    data() {
        return {
            zone: null,
            drawMode: 'add',
            geoResolutionType: 'city',
            geoResolutionQuery: null
        };
    },

    created() {
        this.zone = this.zone || {
            id: null,
            title: null,
            pricePerPeriod: null,
            periodMinutes: 30,
            minimumPeriodCount: 4,
            isActive: true,
            geoFeature: undefined
        };

        this.zone.geoFeature = this.zone.geoFeature || { type: 'Feature', geometry: null };
    },

    async mounted() {
        this.gmaps = await MapHelpers.init();
    },

    methods: {
        async handleSaved(e, shouldClip = false) {
            this.$refs.modal.mask();

            try {
                if (!this.zone.title) this.$throwUserError('Please enter a title.');
                if (!this.zone.pricePerPeriod) this.$throwUserError('Please enter a price per period.');
                if (!this.zone.periodMinutes) this.$throwUserError('Please enter a period duration.');
                if (!this.zone.minimumPeriodCount) this.$throwUserError('Please enter a minimum number of periods.');
                if (!this.zone.geoFeature.geometry)
                    this.$throwUserError('You must add at least one map area to the zone.');

                const response = await this.$http.postOrPut('/api/portal/zones', this.zone.id, {
                    ...this.zone,
                    shouldClip
                });
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();

                // check for an HTTP 409 with an overlappingZones property
                if (err.response?.status === 409 && err.response?.data?.overlappingZones) {
                    // if we got here, it's because we got a conflict from the server
                    const overlappingZonesFriendly = err.response.data.overlappingZones.map(
                        zone => `${zone.title} (overlaps ${zone.overlap} sq mi)`
                    );
                    const confirmationMessage = `This zone clips the following other zones:\n\n${overlappingZonesFriendly}\n\nProceeding will result in the above zones being clipped and the overlapping area reassigned to this zone.`;
                    return this.$confirm('Save Zone', confirmationMessage).then(
                        result => result && this.handleSaved(e, true)
                    );
                }

                this.$alert('Save', err);
            }
        },

        async quickMutate() {
            if (!this.geoResolutionQuery) return;

            this.$refs.modal.mask();

            try {
                const mapCenter = this.$refs.map.currentCenter;
                const response = await this.$http.post('/api/portal/zones/resolve-boundaries', {
                    type: this.geoResolutionType,
                    q: this.geoResolutionQuery,
                    lat: mapCenter.lat,
                    lng: mapCenter.lng
                });

                const fn = this.drawMode === 'add' ? 'addFeatures' : 'removeFeatures';
                GeoJSONTools[fn](this.zone.geoFeature, response.data);
                this.$nextTick(() => this.$refs.map.zoomToFeature());
            } catch (err) {
                this.$alert('Save', err);
            } finally {
                this.$refs.modal.unmask();
            }
        },

        handleMapReady() {
            this.zone.geoFeature.geometry && this.$refs.map.zoomToFeature();
        },

        handleDrawnFeature(feature) {
            const fn = this.drawMode === 'add' ? 'mergeFeature' : 'extractFeature';
            GeoJSONTools[fn](this.zone.geoFeature, feature);
        },

        async handleRemove() {
            if (!(await this.$confirm('Delete Zone', 'Are you sure you want to delete this zone?'))) return;

            this.$refs.modal.mask();

            try {
                await this.$http.delete(`/api/portal/zones/${this.zone.id}`);
                this.$dismiss(false);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Delete Zone', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.modal-inner {
    @apply flex;
}

.fields-wrapper {
    @apply flex flex-col mt-4 w-60 mr-4;
}

.top-fields {
    @apply flex flex-col space-y-3;
}

.row {
    @apply flex space-x-2;

    > * {
        @apply flex-1;
    }
}

.field {
    input {
        @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
    }
}

.drawing-tools {
    @apply mt-auto flex flex-col;

    label {
        @apply mb-1;
    }

    :deep(.switcher) {
        @apply mb-3;

        button {
            @apply flex-1;
        }
    }

    .field {
        @apply flex flex-row;
    }

    :deep(.vf-smart-select) {
        input {
            width: 85px;
        }
    }

    button {
        @apply border rounded-md flex items-center justify-center p-2 ml-1;

        &:hover {
            @apply bg-gray-200;
        }

        i {
            @apply font-normal text-xs;
        }
    }
}

.map-wrapper {
    @apply flex-1 border;
    height: 550px;

    .map {
        @apply w-full h-full;
    }
}
</style>
