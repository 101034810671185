<template>
    <div id="plans">
        <div class="plans-inner" ref="plansInner">
            <div
                v-for="(plan, i) in plans"
                :key="plan.id"
                class="plan-card"
                :class="{ 'most-popular': i === mostPopular }"
            >
                <div class="most-popular-header bg-[#15ABB4]">MOST POPULAR</div>

                <div class="plan-card-inner">
                    <span
                        class="plan-name decoration-black"
                        :style="{ textDecorationColor: colorOverride ?? colors[i] }"
                        >{{ plan.title }}</span
                    >

                    <span class="blurb" v-html="parseMd(plan.summary)" />

                    <p class="plan-price" v-if="plan.duration === 'recurring'">
                        <template v-if="plan.priceMonthly && plan.priceYearly">
                            <span class="amount">${{ plan.priceMonthly }}/month</span>
                            <span class="amount-detail">Billed monthly</span>
                            <span class="amount-detail italic">or ${{ plan.priceYearly }}/year</span>
                        </template>
                        <template v-else-if="plan.priceMonthly">
                            <span class="amount">${{ plan.priceMonthly }}/month</span>
                            <span class="amount-detail">Billed monthly</span>
                        </template>
                        <template v-else>
                            <span class="amount">${{ plan.priceYearly }}/year</span>
                            <span class="amount-detail">Billed annually</span>
                        </template>
                    </p>
                    <p class="plan-price" v-else>
                        <span class="amount">${{ plan.priceIndefinite }}</span>
                        <span class="amount-detail">One-time payment</span>
                        <span class="amount-detail italic">&nbsp;</span>
                    </p>

                    <a class="outline-fill" :href="`https://gosecur.com/get-started?planId=${plan.id}`" target="_parent"
                        >Get Started</a
                    >

                    <div class="plan-features">
                        <span class="features-header">What you get:</span>
                        <div v-html="parseMd(plan.features)" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as md from 'marked';

export default {
    data() {
        return {
            ready: false,
            colorOverride: this.$route.query.underline,
            colors: ['#FCFD54', /*'#15ABB4',*/ '#4361EE'],
            mostPopular: -1,
            plans: []
        };
    },

    async mounted() {
        await this.load();

        window.addEventListener('resize', this.resizeHandler);
        this.resizeHandler();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        resizeHandler() {
            window.parent.postMessage(`contentHeight:${this.$refs.plansInner.offsetHeight}`, '*');
        },

        async load() {
            const response = await this.$http.get('/api/embed/plans', {
                params: {
                    type: this.$route.query.type,
                    limit: this.$route.query.limit
                }
            });
            this.plans = response.data;
        },

        parseMd(text) {
            return md.parse(text).replace(/--/g, '\u2014');
        }
    }
};
</script>

<style lang="scss" scoped>
#plans {
    @apply flex-1;
}

.plans-inner {
    @apply flex justify-center justify-between flex-wrap;
}

h1 {
    @apply text-4xl font-medium;
}

h5 {
    @apply text-base;
}

.plan-card {
    @apply flex flex-col w-full md:w-1/3 p-1 lg:p-3 text-sm 2xl:text-base mx-auto;
}

.most-popular-header {
    @apply flex items-center justify-center rounded-t-xl font-black py-1;
}

.plan-card:not(.most-popular) .most-popular-header {
    @apply hidden md:flex md:invisible;
}

.plan-card-inner {
    @apply flex-1 flex flex-col items-center bg-white text-black px-6 pt-10 pb-14;

    .plan-name {
        @apply text-4xl font-black underline decoration-8 underline-offset-[6px];
    }

    .blurb {
        @apply block mt-6 text-center h-16;
    }

    .plan-price {
        @apply w-full flex flex-col items-center mt-6;

        .amount {
            @apply text-2xl font-bold;
        }
    }

    .outline-fill {
        @apply relative w-full flex items-center justify-center mt-6 text-black bg-white font-bold text-base py-1 border-[3px] border-black overflow-hidden z-10 cursor-pointer duration-75 ease-in-out;

        &:hover {
            @apply text-white;
        }

        &:after {
            @apply absolute w-full h-full bg-black left-0 top-full duration-75 pointer-events-none -z-10 ease-in-out;
            content: '';
        }

        &:hover:after {
            @apply top-0;
        }
    }

    ::v-deep(.plan-features) {
        @apply flex flex-col mt-6 w-full;

        .features-header {
            @apply block text-base font-bold text-center mb-4;
        }

        ul {
            @apply block my-4;
        }

        p {
            @apply text-sm;
        }

        li {
            @apply mt-4 pl-7 text-sm;

            &:before {
                margin-left: -28px;
                position: absolute;
                content: '\f00c';
                font-family: 'Font Awesome 5 Pro';
                font-weight: 800;
            }
        }
    }
}
</style>
