<template>
    <TransitionRoot as="template" :show="isOpen">
        <Dialog as="div" static class="fixed z-10 inset-0 overflow-y-auto" :initialFocus="_getInitialFocusHack()">
            <div class="modal-overlay-container">
                <TransitionChild
                    as="template"
                    enter="ease-out duration-750"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="modal-overlay" />
                </TransitionChild>

                <!-- This element is to trick the browser into centering the modal contents. -->
                <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
                <TransitionChild
                    as="template"
                    enter="ease-out duration-300"
                    enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    enter-to="opacity-100 translate-y-0 sm:scale-100"
                    leave="ease-in duration-200"
                    leave-from="opacity-100 translate-y-0 sm:scale-100"
                    leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                    <form
                        v-on:submit.prevent
                        autocomplete="off"
                        class="modal overflow-visible"
                        :class="[
                            {
                                sm: 'sm:max-w-sm',
                                md: 'sm:max-w-md',
                                lg: 'sm:max-w-lg',
                                xl: 'sm:max-w-xl',
                                '2xl': 'sm:max-w-2xl',
                                '3xl': 'sm:max-w-3xl',
                                '4xl': 'sm:max-w-4xl',
                                '5xl': 'sm:max-w-5xl',
                                '6xl': 'sm:max-w-6xl'
                            }[maxWidth] ?? 'sm:max-w-2xl',
                            this.fullWidth !== undefined && 'full'
                        ]"
                    >
                        <div>
                            <div class="header-wrap">
                                <DialogTitle as="div" class="modal-title">
                                    <slot name="title"></slot>
                                </DialogTitle>
                                <ModalMenu v-if="menuItems" :items="menuItems" />
                            </div>

                            <slot name="content"></slot>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer" />
                            <button
                                v-if="_buttons.includes('delete')"
                                type="button"
                                @click="remove()"
                                class="modal-button destroy"
                            >
                                {{ buttonText?.delete ?? 'Delete' }}
                            </button>
                            <button
                                v-if="_buttons.includes('save')"
                                type="submit"
                                @click="save"
                                class="modal-button confirm"
                            >
                                {{ saveTitle ?? buttonText?.save ?? 'Save' }}
                            </button>
                            <button
                                v-if="_buttons.includes('cancel')"
                                :type="!_buttons.includes('save') ? 'submit' : 'button'"
                                @click="cancel"
                                class="modal-button cancel"
                            >
                                {{ buttonText?.cancel ?? 'Cancel' }}
                            </button>
                            <button
                                v-if="_buttons.includes('close')"
                                :type="!_buttons.includes('save') ? 'submit' : 'button'"
                                @click="cancel"
                                class="modal-button cancel"
                            >
                                Close
                            </button>
                        </div>
                        <div v-if="isMasked" class="modal-mask">
                            <i class="fad fa-spinner-third fa-spin text-5xl" />
                        </div>
                    </form>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

import ModalMenu from './c-modal-menu';

export default {
    props: ['fullWidth', 'maxWidth', 'buttons', 'buttonText', 'saveTitle', 'confirmDisabled', 'menuItems'],

    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        ModalMenu,
        TransitionChild,
        TransitionRoot
    },

    computed: {
        _buttons() {
            return this.buttons || [];
        }
    },

    data() {
        return {
            isOpen: false,
            isMasked: false
        };
    },

    created() {
        window.addEventListener('keydown', this.handleKeyDown_TabFix);
    },

    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyDown_TabFix);
    },

    mounted() {
        this.isOpen = true;
    },

    methods: {
        // https://github.com/tailwindlabs/headlessui/issues/825
        _getInitialFocusHack() {
            return document.activeElement;
        },

        save() {
            this.$emit('saved');
        },

        cancel() {
            this.$parent.$dismiss();
        },

        remove() {
            this.$emit('removed');
        },

        mask() {
            this.isMasked = true;
        },

        unmask() {
            this.isMasked = false;
        },

        handleKeyDown_TabFix(e) {
            if (e.key === 'Tab') {
                e.stopImmediatePropagation();
            }
        }
    }
};
</script>

<style lang="scss">
.modal-overlay-container {
    @apply flex items-end justify-center min-h-full pt-4 px-4 pb-20 text-center sm:block sm:p-0;
}
.modal-overlay {
    @apply fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}

.modal {
    @apply inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:p-6;

    &.full {
        @apply sm:w-full;
    }

    .modal-title {
        @apply text-lg leading-6 font-bold text-gray-900;
    }

    .modal-footer {
        @apply mt-2 sm:mt-4 sm:flex sm:flex-row justify-end;

        .modal-button {
            @apply w-full inline-flex justify-center rounded-md shadow-sm text-base font-medium px-4 py-2 sm:w-auto sm:text-sm focus:outline-none focus:ring-2 focus:ring-offset-2;

            &.destroy {
                @apply border border-transparent bg-red-600 text-white hover:bg-red-700 focus:ring-red-500 sm:mr-auto;
            }

            &.confirm {
                @apply border border-transparent bg-blue-600 text-white hover:bg-blue-700 focus:ring-blue-500 sm:ml-3;
            }

            &.cancel {
                @apply border border-gray-300 bg-white text-gray-700 hover:bg-gray-100 focus:ring-blue-500 sm:mt-0 sm:ml-3;
            }
        }
    }

    button.confirm {
        &[disabled] {
            @apply cursor-default bg-blue-300 hover:bg-blue-300 text-gray-100;
        }
    }

    .modal-mask {
        @apply absolute top-0 left-0 w-full h-full bg-white bg-opacity-50 flex justify-center items-center;
    }

    .header-wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}
</style>
