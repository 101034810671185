<template>
    <modal ref="modal" maxWidth="sm" :buttons="['cancel', 'save']" saveTitle="Save Membership" @saved="save">
        <template v-slot:title>{{ client.membership ? 'Update' : 'Enroll' }} Membership</template>
        <template v-slot:content>
            <div>
                <div class="mt-2 mb-1">Membership</div>
                <smart-select class="membership" v-model="selectedPlan" :options="plans" titleKey="title" />
            </div>
            <div v-if="selectedPlan && selectedPlan.duration !== 'indefinite'">
                <div class="mt-2 mb-1">Period</div>
                <smart-select
                    class="duration"
                    v-model="membership.duration"
                    :options="{ month: 'Month', year: 'Year' }"
                />
            </div>
            <div>
                <div class="mt-4 mb-1">Cost</div>
                <div class="flex items-center">
                    <div>$</div>
                    <div class="px-2">
                        <input
                            v-model="membership.price"
                            type="number"
                            step="0.01"
                            autocomplete="noautocomplete"
                            class="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                        />
                    </div>
                    <div v-if="selectedPlan">
                        {{ selectedPlan.duration === 'indefinite' ? 'once' : `/${membership.duration}` }}
                    </div>
                </div>
            </div>
            <div class="mt-6 flex">
                <SwitchGroup as="div">
                    <Switch
                        v-model="membership.isAutoRenewEnabled"
                        :class="[
                            membership.isAutoRenewEnabled ? 'bg-blue-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        ]"
                    >
                        <span class="sr-only">Auto-renew</span>
                        <span
                            aria-hidden="true"
                            :class="[
                                membership.isAutoRenewEnabled ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            ]"
                        />
                    </Switch>
                </SwitchGroup>
                <div class="ml-2 flex-1">Auto-Renew</div>
            </div>

            <div v-if="!client.membership" class="mt-2 flex">
                <SwitchGroup as="div">
                    <Switch
                        v-model="shouldBillInitially"
                        :class="[
                            shouldBillInitially ? 'bg-blue-600' : 'bg-gray-200',
                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                        ]"
                    >
                        <span class="sr-only">Auto-renew</span>
                        <span
                            aria-hidden="true"
                            :class="[
                                shouldBillInitially ? 'translate-x-5' : 'translate-x-0',
                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                            ]"
                        />
                    </Switch>
                </SwitchGroup>
                <div class="ml-2 flex-1">Bill immediately</div>
            </div>
        </template>
    </modal>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';

import Modal from '@/common/components/c-modal';

const durationFieldMap = {
    indefinite: 'priceIndefinite',
    month: 'priceMonthly',
    year: 'priceYearly'
};

export default {
    components: { Modal, Switch, SwitchGroup },

    data() {
        return {
            client: null,
            plans: [],
            selectedPlan: null,
            membership: {
                planId: null,
                duration: null,
                price: null,
                isAutoRenewEnabled: true
            },
            shouldBillInitially: true
        };
    },

    watch: {
        selectedPlan() {
            if (this.membership.planId !== this.selectedPlan.id) {
                this.membership.planId = this.selectedPlan.id;
                this.membership.duration = this.selectedPlan.duration === 'indefinite' ? 'indefinite' : 'month';
            }
        },

        'membership.duration'() {
            if (this.selectedPlan) {
                this.membership.price = this.selectedPlan[durationFieldMap[this.membership.duration]];
            }
        }
    },

    created() {
        if (this.client.membership) {
            this.membership = {
                planId: this.client.membership.plan.id,
                duration: this.client.membership.duration,
                price: this.client.membership.price,
                isAutoRenewEnabled: this.client.membership.isAutoRenewEnabled
            };
        }
    },

    async mounted() {
        const response = await this.$http.get('/api/portal/plans');
        this.plans = response.data;
        this.selectedPlan = this.membership.planId ? this.plans.find(plan => plan.id === this.membership.planId) : null;
    },

    methods: {
        async save() {
            if (!this.membership.planId) return this.$alert('Save', 'Please select a membership plan.');
            if (!String(this.membership.price).length) return this.$alert('Save', 'Please enter a membership cost.');

            this.$refs.modal.mask();

            try {
                const response = await this.$http.put(`/api/portal/clients/${this.client.id}`, {
                    membership: {
                        ...this.membership,
                        shouldBillInitially: this.shouldBillInitially
                    }
                });
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Add Credit', err);
            }
        }
    }
};
</script>

<style lang="scss"></style>
