<template>
    <table-component clickable hoverable :columns="columns" :data="data" :isLoading="isLoading">
        <template v-slot:empty>No scheduled requests match your search criteria.</template>
        <template v-if="data" v-slot:rows>
            <tr v-for="sr in data" :key="sr.id" @click="viewDetails(sr)">
                <td>{{ sr.id }}</td>
                <td>{{ sr.client?.firstName }} {{ sr.client?.lastName }}</td>
                <td class="multiline">
                    <div class="primary">
                        {{ sr.address.slice(0, sr.address.indexOf('\n')) }}
                    </div>
                    <div class="secondary">
                        {{ sr.address.slice(sr.address.indexOf('\n')) }}
                    </div>
                </td>
                <td>
                    {{ $filter(sr.when, 'dateTime') }}
                </td>
                <td>{{ sr.duration ?? '-' }}</td>
                <td>
                    <template v-if="sr.quoteCustomerPrice">{{ $filter(sr.quoteCustomerPrice, 'usCurrency') }}</template>
                    <template v-else>-</template>
                </td>
                <td>
                    <template v-if="sr.quoteOfficerCount">{{
                        `${sr.quoteOfficerCount - sr.dispatchPendingCount}/${sr.quoteOfficerCount}`
                    }}</template>
                    <template v-else>-</template>
                </td>
                <td class="status-cell">
                    <span :class="`status-text ${getStatusColor(sr.status)}`">{{ sr.status.ucfirst() }}</span>
                    <!-- add: pending officer report, pending client report -->
                </td>
            </tr>
        </template>
    </table-component>
</template>

<script>
import TableComponent from '@/common/components/c-table';

import PrescheduledDetail from './m-prescheduled-detail.vue';

const STATUS_MAP = {
    pending: 'yellow',
    quoted: 'pink',
    accepted: 'blue',
    completed: 'green',
    active: 'red',
    canceled: 'gray'
};

export default {
    components: {
        TableComponent
    },

    data() {
        return {
            isLoading: true,
            canLoadMore: true,
            data: null
        };
    },

    computed: {
        columns() {
            return ['ID', 'Client', 'Location', 'Date/Time', 'Est. Duration', 'Quoted Rate', 'Officers', 'Status'];
        }
    },

    mounted() {
        this.loadRequests();
    },

    ancestorScrolledToBottom() {
        this.loadMoreRequests();
    },

    methods: {
        async loadRequests() {
            const response = await this.$http.get('/api/portal/prescheduled');
            this.data = response.data;
            this.isLoading = false;
            this.canLoadMore = this.data.length > 0;
        },

        async loadMoreRequests() {
            if (this.isLoading) return;
            if (!this.canLoadMore) return;
            this.isLoading = true;
            const response = await this.$http.get('/api/portal/prescheduled', {
                params: {
                    beforeId: this.data[this.data.length - 1].id
                }
            });
            this.data.push(...response.data);
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        getStatusColor(status) {
            return STATUS_MAP[status];
        },

        async viewDetails(sr) {
            await this.$modal(PrescheduledDetail, { sr });
            this.loadRequests();
        }
    }
};
</script>

<style lang="scss" scoped>
table {
    &.hoverable {
        tbody {
            tr:not(.loading):hover {
                @apply cursor-pointer;
            }
        }
    }
}
</style>
