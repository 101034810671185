import '@fortawesome/fontawesome-pro/css/all.css';

import SentryRRWeb from '@sentry/rrweb';
import { BrowserTracing } from '@sentry/tracing';
import * as Sentry from '@sentry/vue';
import VueFoundation from '@signal24/vue-foundation';
import { format, formatDuration, intervalToDuration } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import App from './app';
import router from './router';

const appInstance = VueFoundation.getApp();

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        app: appInstance,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        environment: process.env.VUE_APP_ENV,
        release: 'secur-portal@' + process.env.VUE_APP_VERSION,
        integrations: [
            new BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router)
            }),
            new SentryRRWeb({
                maskAllInputs: false
            })
        ],
        logErrors: true
    });

    appInstance.config.globalProperties.$http.interceptors.request.use(config => {
        const requestId = uuidv4();
        config.headers['x-request-id'] = requestId;
        return config;
    });

    appInstance.config.globalProperties.$http.interceptors.response.use(
        response => response,
        err => {
            if (err.config && err.response?.status !== 422) {
                Sentry.withScope(scope => {
                    scope.setTag('request-id', err.config.headers['x-request-id']);
                    Sentry.captureException(err);
                });
            }
            throw err;
        }
    );
}

appInstance.use(router);

appInstance.config.globalProperties.$http.defaults.baseURL = process.env.VUE_APP_API_URL;
appInstance.config.globalProperties.$http.interceptors.request.use(config => {
    try {
        if (localStorage.securSessionJwt) {
            config.headers.Authorization = 'Bearer ' + localStorage.securSessionJwt;
        }
    } catch (err) {
        // do nothing
    }

    return config;
});

// configure some variable settings
VueFoundation.configure({
    unauthorizedHttpResponseHandler() {
        appInstance.vm.store.session = null;
    }
});

VueFoundation.registerFilters({
    longDateTime(input) {
        input = new Date(input);
        return format(input, 'MMMM Lo, y - h:mm a');
    },

    dateTime(input) {
        input = new Date(input);
        return format(input, 'M/dd/yy h:mm a');
    },

    date(input) {
        input = new Date(input);
        return format(input, 'M/dd/yyyy');
    },

    time(input) {
        input = new Date(input);
        return format(input, 'h:mm a');
    },

    duration([start, end]) {
        let duration = intervalToDuration({
            start: new Date(start),
            end: new Date(end)
        });

        return formatDuration(duration, {
            format: ['hours', 'minutes']
        });
    },

    shortMoney(input) {
        return input.replace(/\.00$/, '');
    }
});

VueFoundation.mount(App, 'body');
