<template>
    <div class="avatar">
        <img v-if="person.photoUuid" class="flex-1" :src="`${baseUrl}/api/user-photo/${person.photoUuid}`" />
        <i v-else class="fa fa-user" />
    </div>
</template>

<script>
const baseUrl = process.env.VUE_APP_API_URL;

export default {
    props: ['person'],

    computed: {
        baseUrl() {
            return baseUrl;
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    @apply rounded-full bg-gray-400 text-white flex items-center justify-center w-10 h-10 overflow-hidden;
}
</style>
