<template>
    <div class="flex-1 flex overflow-hidden bg-gray-200">
        <TransitionRoot as="template" :show="sidebarOpen">
            <Dialog
                as="div"
                static
                class="fixed inset-0 flex z-40 md:hidden"
                @close="sidebarOpen = false"
                :open="sidebarOpen"
            >
                <TransitionChild
                    as="template"
                    enter="transition-opacity ease-linear duration-300"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="fixed inset-0 bg-gray-600 bg-opacity-75" />
                </TransitionChild>
                <TransitionChild
                    as="template"
                    enter="transition ease-in-out duration-300 transform"
                    enter-from="-translate-x-full"
                    enter-to="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leave-from="translate-x-0"
                    leave-to="-translate-x-full"
                >
                    <div class="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-black">
                        <TransitionChild
                            as="template"
                            enter="ease-in-out duration-300"
                            enter-from="opacity-0"
                            enter-to="opacity-100"
                            leave="ease-in-out duration-300"
                            leave-from="opacity-100"
                            leave-to="opacity-0"
                        >
                            <div class="absolute top-0 right-0 -mr-12 pt-2">
                                <button
                                    class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                    @click="sidebarOpen = false"
                                >
                                    <span class="sr-only">Close sidebar</span>
                                    <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                </button>
                            </div>
                        </TransitionChild>
                        <div class="flex-shrink-0 flex items-center px-4">
                            <img class="h-6 w-auto" src="/assets/secur-wordmark.png" alt="Secur" />
                        </div>
                        <div class="mt-5 flex-1 h-0 overflow-y-auto">
                            <nav class="px-2 space-y-1">
                                <router-link
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :to="item.href"
                                    :class="[
                                        $route?.name?.toLowerCase() === item.name.toLowerCase()
                                            ? 'bg-black text-white'
                                            : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                        'group flex items-center px-2 py-3 text-base rounded-md'
                                    ]"
                                    @click="sidebarOpen = false"
                                >
                                    <i
                                        :class="[
                                            item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                                            'mr-4 flex-shrink-0 h-6 w-6 text-xl',
                                            `fa fa-${item.icon}`
                                        ]"
                                    />
                                    {{ item.name }}
                                </router-link>
                            </nav>
                        </div>
                    </div>
                </TransitionChild>
                <div class="flex-shrink-0 w-14" aria-hidden="true">
                    <!-- Dummy element to force sidebar to shrink to fit close icon -->
                </div>
            </Dialog>
        </TransitionRoot>

        <!-- Static sidebar for desktop -->
        <div class="hidden md:flex md:flex-shrink-0">
            <div class="flex flex-col w-64">
                <!-- Sidebar component, swap this element with another sidebar if you like -->
                <div class="flex flex-col h-0 flex-1">
                    <div class="flex items-center justify-center h-16 flex-shrink-0 px-4 bg-black">
                        <img class="h-12 w-auto cursor-pointer" src="/assets/secur-wordmark.png" alt="Secur" />
                    </div>
                    <div class="flex-1 flex flex-col overflow-y-auto">
                        <nav class="flex-1 px-2 py-4 bg-black space-y-1">
                            <router-link
                                v-for="item in navigation"
                                :key="item.name"
                                :to="item.href"
                                :class="[
                                    $route?.path === item.href
                                        ? 'bg-white text-black'
                                        : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                                    'group flex items-center px-2 py-3 text-sm rounded-md'
                                ]"
                            >
                                <i
                                    :class="[
                                        $route?.path === item.href ? '' : 'text-gray-400 group-hover:text-gray-300',
                                        'mr-4 flex-shrink-0 h-6 w-6 text-xl',
                                        `fa fa-${item.icon}`
                                    ]"
                                />
                                {{ item.name }}
                            </router-link>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-col w-0 flex-1 overflow-hidden">
            <div class="relative z-10 flex-shrink-0 flex h-16 bg-black shadow">
                <button
                    class="px-4 border-r border-gray-700 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                    @click="sidebarOpen = true"
                >
                    <span class="sr-only">Open sidebar</span>
                    <MenuAlt2Icon class="h-6 w-6" aria-hidden="true" />
                </button>
                <div class="flex-1 px-4 flex justify-between">
                    <div class="flex-1 flex">
                        <div
                            v-if="environmentText"
                            class="flex-1 flex items-center justify-center text-red-400 text-2xl"
                        >
                            {{ environmentText }}
                        </div>
                    </div>
                    <div class="ml-4 flex items-center md:ml-6">
                        <!-- Profile dropdown -->
                        <Menu as="div" class="ml-3 relative">
                            <div>
                                <MenuButton
                                    class="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    <span class="sr-only">Open user menu</span>
                                    <Avatar :person="session" />
                                </MenuButton>
                            </div>
                            <transition
                                enter-active-class="transition ease-out duration-100"
                                enter-from-class="transform opacity-0 scale-95"
                                enter-to-class="transform opacity-100 scale-100"
                                leave-active-class="transition ease-in duration-75"
                                leave-from-class="transform opacity-100 scale-100"
                                leave-to-class="transform opacity-0 scale-95"
                            >
                                <MenuItems
                                    class="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                                >
                                    <MenuItem v-for="item in userNavigation" :key="item.name">
                                        <a
                                            :href="item.href"
                                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                            @click.prevent="handleUserMenuItemClicked(item.action)"
                                            >{{ item.name }}</a
                                        >
                                    </MenuItem>
                                </MenuItems>
                            </transition>
                        </Menu>
                    </div>
                </div>
            </div>

            <main class="flex-1 min-h-0 relative overflow-y-auto focus:outline-none">
                <!-- <div
          class="flex items-center max-w-7xl mx-auto px-0 sm:px-6 md:px-8"
        ></div> -->
                <div class="max-w-8xl mx-auto px-0 sm:px-6 md:px-8 min-h-full flex flex-col">
                    <!-- Replace with your content -->
                    <slot />
                    <!-- /End replace -->
                </div>
            </main>
        </div>
    </div>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue';
import {
    // BellIcon,
    MenuAlt2Icon,
    XIcon
} from '@heroicons/vue/outline';

import Avatar from './c-avatar';
// import { SearchIcon } from "@heroicons/vue/solid";

export default {
    store: ['session', 'permissions'],

    components: {
        Avatar,
        Dialog,
        DialogOverlay,
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        TransitionChild,
        TransitionRoot,
        // BellIcon,
        MenuAlt2Icon,
        // SearchIcon,
        XIcon
    },

    data() {
        return {
            sidebarOpen: false
        };
    },

    computed: {
        environmentText() {
            if (process.env.VUE_APP_ENV === 'production') return null;
            return `*** ${process.env.VUE_APP_ENV} environment ***`;
        },

        navigation() {
            const clientsName = this.session.accessLevel === 'sysadmin' ? 'Clients' : 'Employees';

            return [
                {
                    permission: 'requests',
                    name: 'Requests',
                    href: '/requests',
                    icon: 'clock'
                },
                {
                    permission: 'prescheduled',
                    name: 'Pre-Scheduled Requests',
                    href: '/prescheduled',
                    icon: 'calendar'
                },
                {
                    permission: 'agencies',
                    name: 'Agencies',
                    href: '/agencies',
                    icon: 'shield-alt'
                },
                {
                    permission: 'officers',
                    name: 'Officers',
                    href: '/officers',
                    icon: 'user-shield'
                },
                {
                    permission: 'clients',
                    name: clientsName,
                    href: '/clients',
                    icon: 'users'
                },
                {
                    permission: 'zones',
                    name: 'Zones',
                    href: '/zones',
                    icon: 'map'
                },
                {
                    permission: 'membership',
                    name: 'Membership Plans',
                    href: '/membership-plans',
                    icon: 'usd-circle'
                },
                {
                    permission: 'promoCodes',
                    name: 'Promo Codes',
                    href: '/promo-codes',
                    icon: 'tags'
                }
            ].filter(item => this.permissions && this.permissions[item.permission] === true);
        },

        userNavigation() {
            return [
                // { name: 'Settings', href: '', action: '' },
                {
                    name: 'Sign out',
                    href: '',
                    action: 'logout'
                }
            ];
        }
    },

    methods: {
        handleUserMenuItemClicked(action) {
            if (action === 'openSettings') this.openSettings();
            else if (action === 'logout') this.logout();
        },

        async logout() {
            await this.$http.post('/api/auth/logout');
            this.session = null;
            localStorage.removeItem('securSessionJwt');
        }
    }
};
</script>

<style type="text/scss" scoped>
.max-w-8xl {
    max-width: 1440px;
}
</style>
