<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="flex-1 text-sm">
        <div class="flex flex-row justify-between">
            <div>
                <div class="text-gray-500 text-xs">Client</div>
                <div>{{ sr.client.firstName }} {{ sr.client.lastName }}</div>
            </div>

            <div>
                <div class="mt-1">
                    <button class="button-basic w-full sm:text-xs" @click="viewQuestionnaire">
                        View Questionnaire
                    </button>
                </div>
            </div>

            <div>
                <div class="text-gray-500 text-xs text-right">Requested At</div>
                <div>{{ $filter(sr.createdAt, 'dateTime') }}</div>
            </div>
        </div>

        <Map
            class="mt-4 h-64"
            :center="coordinateLatLng"
            :marker="coordinateLatLng"
            :options="{ disableDefaultUI: true }"
            :zoom="14"
        />

        <div class="mt-4 flex flex-row">
            <div class="w-2/3">
                <div class="text-gray-500 text-xs">Location</div>
                <div class="mt-1">
                    <input v-if="isEditable" v-model="address1L" type="text" class="text-input" autocomplete="off" />
                    <template v-else>{{ address1L }}</template>
                </div>
            </div>

            <div class="w-1/3 ml-4">
                <div class="text-gray-500 text-xs text-right">Request Time</div>
                <div class="text-right mt-1">
                    <input v-if="isEditable" v-model="whenDTL" type="datetime-local" class="text-input" />
                    <template v-else>{{ $filter(sr.when, 'dateTime') }}</template>
                </div>
            </div>
        </div>

        <div class="mt-4 flex flex-row">
            <div class="flex-1">
                <div class="text-gray-500 text-xs">Base Price ($)</div>
                <div class="mt-1">
                    <input
                        v-if="isEditable"
                        v-model="sr.quoteCustomerPrice"
                        type="number"
                        class="text-input"
                        step="0.01"
                        min="0"
                    />
                    <template v-else>{{ $filter(sr.quoteCustomerPrice, 'usCurrency') }}</template>
                </div>
            </div>

            <div class="flex-1 ml-4">
                <div class="text-gray-500 text-xs">
                    Overage
                    <span class="bg-gray-200 rounded-sm px-1" v-tip="'$ per 30 mins per officer'">i</span>
                </div>
                <div class="mt-1">
                    <input
                        v-if="isEditable"
                        v-model="sr.quoteCustomerOveragePrice"
                        type="number"
                        class="text-input"
                        step="0.01"
                        min="0"
                    />
                    <template v-else>{{ $filter(sr.quoteCustomerOveragePrice, 'usCurrency') }}</template>
                </div>
            </div>

            <div class="ml-4">
                <div class="text-gray-500 text-xs">Plain Clothes</div>
                <div class="mt-1">
                    <SwitchGroup v-if="isEditable" as="div" class="switch-group">
                        <Switch
                            v-model="sr.quoteOfficerPlainclothes"
                            :class="[sr.quoteOfficerPlainclothes && 'on', 'switch']"
                        >
                            <span aria-hidden="true" :class="[sr.quoteOfficerPlainclothes && 'on', 'thumb']" />
                        </Switch>
                    </SwitchGroup>
                    <template v-else>{{ sr.quoteOfficerPlainclothes ? 'Yes' : 'No' }}</template>
                </div>
            </div>

            <div class="flex-1 ml-4">
                <div class="text-gray-500 text-xs"># Officers</div>
                <div class="mt-1">
                    <input
                        v-if="isEditable"
                        v-model="sr.quoteOfficerCount"
                        type="number"
                        class="text-input"
                        step="1"
                        min="1"
                    />
                    <template v-else>{{ sr.quoteOfficerCount ?? '-' }}</template>
                </div>
            </div>

            <div class="flex-1 ml-4">
                <div class="text-gray-500 text-xs">Duration (hrs)</div>
                <div class="mt-1">
                    <input v-if="isEditable" v-model="sr.duration" type="number" class="text-input" step="1" min="1" />
                    <template v-else>{{ sr.duration ?? '-' }}</template>
                </div>
            </div>
        </div>

        <div class="mt-4 flex flex-row">
            <div class="flex-1">
                <div class="text-gray-500 text-xs">Preferred Officers</div>
                <preferred-officers
                    v-model="preferredOfficers"
                    :disable-edit="!['pending', 'quoted'].includes(sr.status)"
                />
            </div>
        </div>

        <div class="mt-4 flex flex-row">
            <div class="flex-1">
                <div class="text-gray-500 text-xs">Notes to Customer</div>
                <div class="mt-1">
                    <textarea v-if="isEditable" v-model="sr.quoteCustomerNotes" class="text-input" />
                    <div v-else v-user-text="sr.quoteCustomerNotes"></div>
                </div>
            </div>

            <div class="flex-1 ml-4">
                <div class="text-gray-500 text-xs">Notes to Officers</div>
                <div class="mt-1">
                    <textarea v-model="sr.quoteOfficerNotes" class="text-input" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';
import { format } from 'date-fns';

import Map from '@/common/components/c-map';

import PreferredOfficers from './f-overview/c-preferred-officers';
import Questionnaire from './f-overview/m-questionnaire';

export default {
    store: ['session'],
    props: ['sr', 'modal'],

    components: {
        Map,
        Switch,
        SwitchGroup,
        PreferredOfficers
    },

    data() {
        return {
            preferredOfficers: this.sr.officerResponses.filter(r => r.type === 'preferred').map(r => r.officer)
        };
    },

    computed: {
        coordinateLatLng() {
            return {
                lat: this.sr.coordinate.y,
                lng: this.sr.coordinate.x
            };
        },

        isEditable() {
            return ['pending', 'quoted'].includes(this.sr.status);
        },

        address1L: {
            get() {
                return this.sr.address.replace(/\n/, ', ');
            },

            set(val) {
                // eslint-disable-next-line vue/no-mutating-props
                this.sr.address = val
                    .replace(',', '\n')
                    .split(/\n/)
                    .map(l => l.trim())
                    .join('\n');
            }
        },

        whenDTL: {
            get() {
                return format(new Date(this.sr.when), `yyyy-MM-dd'T'HH:mm`);
            },

            set(val) {
                // eslint-disable-next-line vue/no-mutating-props
                this.sr.when = new Date(val).toISOString();
            }
        }
    },

    methods: {
        viewQuestionnaire() {
            this.$modal(Questionnaire, { qs: this.sr.questionnaire });
        },

        async handleSaved() {
            this.modal.mask();

            try {
                const payload = this.getSavePayload();
                await this.$http.put(`/api/portal/prescheduled/${this.sr.id}`, payload);
                this.modal.$dismiss(false);
            } catch (err) {
                this.modal.unmask();
                this.$alert('Save Request', err);
            }
        },

        getSavePayload() {
            if (!this.isEditable) {
                return { quoteOfficerNotes: this.sr.quoteOfficerNotes };
            }

            if (!this.sr.quoteCustomerPrice) this.$throwUserError('Please provide a base price.');
            if (!this.sr.quoteCustomerOveragePrice) this.$throwUserError('Please provide an overage price.');
            if (!this.sr.quoteOfficerCount) this.$throwUserError('Please provide an officer count.');
            if (!this.sr.duration) this.$throwUserError('Please provide a duration.');

            return {
                status: 'quoted',
                when: this.sr.when,
                duration: this.sr.duration,
                quoteCustomerPrice: this.sr.quoteCustomerPrice,
                quoteCustomerOveragePrice: this.sr.quoteCustomerOveragePrice,
                quoteOfficerCount: this.sr.quoteOfficerCount,
                quoteOfficerPlainclothes: this.sr.quoteOfficerPlainclothes,
                quoteCustomerNotes: this.sr.quoteCustomerNotes,
                quoteOfficerNotes: this.sr.quoteOfficerNotes,
                preferredOfficerIds: this.preferredOfficers.map(o => o.id)
            };
        },

        async handleRemoved() {
            const confirmed = await this.$confirm(
                'Decline Request',
                'Are you sure you want to decline this request? Customer notes will be saved and the customer will be notified.'
            );
            if (!confirmed) return;

            this.modal.mask();

            try {
                await this.$http.put(`/api/portal/prescheduled/${this.sr.id}`, {
                    quoteCustomerNotes: this.sr.quoteCustomerNotes,
                    status: 'canceled'
                });
                this.modal.$dismiss(false);
            } catch (err) {
                this.modal.unmask();
                this.$alert('Decline Request', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
