<template>
    <li>
        <div class="relative pb-8">
            <span v-if="isLast" class="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
            <div class="relative flex space-x-3">
                <div>
                    <span
                        :class="[
                            event.type != 'update'
                                ? 'bg-green-500'
                                : event.source == 'client'
                                ? 'bg-red-600'
                                : 'bg-blue-600',
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white'
                        ]"
                    >
                        <i v-if="event.type == 'sms'" class="fas fa-phone text-white" />
                        <i v-else-if="event.type == 'call'" class="fas fa-comment-dots text-white" />
                        <i v-else :class="`fas fa-${event.source == 'client' ? 'user' : 'shield-alt'} text-white`" />
                    </span>
                </div>
                <div class="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                    <div>
                        <p class="text-sm text-gray-500" v-html="event.content" />
                        <p
                            v-if="event.type == 'sms' && event.secondaryContent"
                            class="text-sm text-gray-600 bg-gray-100 p-3 rounded-md"
                            v-html="event.secondaryContent"
                        />
                        <div
                            v-if="event.type == 'call' && event.secondaryContent"
                            class="text-sm text-gray-600 bg-gray-100 p-0 rounded-md"
                        >
                            <audio :src="event.secondaryContent" controls />
                        </div>
                    </div>
                    <div class="text-right text-sm whitespace-nowrap text-gray-500">
                        <time :datetime="event.datetime">
                            {{ $filter(event.dateTime, 'time') }}
                        </time>
                    </div>
                </div>
            </div>
        </div>
    </li>
</template>

<script>
export default {
    props: ['event', 'idx', 'isLast']
};
</script>

<style></style>
