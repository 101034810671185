<template>
    <div v-if="officers" class="mt-1">
        <Combobox as="div" v-model="selection" multiple class="w-full border-none p-0">
            <div v-if="disableEdit">
                <div v-if="selection.length > 0" class="flex flex-wrap gap-1 align-center text-sm py-1">
                    <div
                        v-for="officer in selection"
                        :key="officer.id"
                        class="px-2 py-1 border border-gray-300 rounded-full flex align-center justify-center font-semibold text-sm"
                        v-tip="`${officer.firstName} ${officer.lastName}`"
                    >
                        {{ officer.firstName }} {{ officer.lastName.substring(0, 1) }}.
                    </div>
                </div>
                <div v-else>No officers selected.</div>
            </div>

            <div
                v-else
                class="w-full flex flex-wrap relative mt-1 rounded-md border border-gray-300 bg-white py-1 pr-8 shadow-sm focus-within:border-indigo-500 focus-within:outline-none focus-within:ring-1 focus-within:ring-indigo-500 text-sm"
            >
                <div v-if="selection.length > 0" class="flex flex-wrap gap-1 align-center ml-3 text-sm py-1">
                    <div
                        v-for="(officer, i) in selection"
                        :key="officer.id"
                        class="px-2 py-1 border border-gray-300 rounded-full flex align-center justify-center font-semibold text-sm"
                    >
                        {{ officer.firstName }} {{ officer.lastName.substring(0, 1) }}.
                        <button class="ml-2" @click="removeOfficer(i)"><i class="fal fa-times" /></button>
                    </div>
                </div>

                <ComboboxInput
                    class="flex-1 px-3 py-2 pr-2 border-0 focus:border-0 focus:ring-0 text-sm"
                    placeholder="Type a name to search..."
                    @change="query = $event.target.value"
                />
                <ComboboxButton
                    class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
                >
                    <ChevronDownIcon class="h-5 w-5 text-gray-400" aria-hidden="true" />
                </ComboboxButton>

                <ComboboxOptions
                    class="absolute top-full z-10 max-h-40 mt-1 w-full overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                    <ComboboxOption
                        v-for="officer in filteredOfficers"
                        :key="officer.id"
                        :value="officer"
                        as="template"
                        v-slot="{ active, selected }"
                    >
                        <li
                            :class="[
                                'relative cursor-default select-none py-2 pl-3 pr-9',
                                active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                            ]"
                        >
                            <div class="flex items-center">
                                <span :class="['ml-3 truncate', selected && 'font-semibold']">
                                    {{ officer.firstName }} {{ officer.lastName }}
                                </span>
                            </div>

                            <span
                                v-if="selected"
                                :class="[
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    active ? 'text-white' : 'text-indigo-600'
                                ]"
                            >
                                <CheckIcon class="h-5 w-5" aria-hidden="true" />
                            </span>
                        </li>
                    </ComboboxOption>
                </ComboboxOptions>
            </div>
        </Combobox>
    </div>
</template>

<script>
import { Combobox, ComboboxButton, ComboboxInput, ComboboxOption, ComboboxOptions } from '@headlessui/vue';
import { CheckIcon, ChevronDownIcon } from '@heroicons/vue/outline';
import { debounce } from 'lodash';

export default {
    props: ['modelValue', 'disableEdit'],

    components: {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxOption,
        ComboboxOptions,
        ChevronDownIcon,
        CheckIcon
    },

    data() {
        return {
            selection: this.modelValue ?? [],
            officers: null,
            query: ''
        };
    },

    watch: {
        modelValue() {
            this.selection = this.modelValue;
        },

        selection() {
            this.$emit('update:modelValue', this.selection);
        },

        query: debounce(function () {
            this.loadOfficers();
        }, 250)
    },

    computed: {
        filteredOfficers() {
            return this.officers.filter(officer => {
                let query = this.query.toLowerCase();
                let oName = `${officer.firstName} ${officer.lastName}`;
                return oName.toLowerCase().includes(query);
            });
        }
    },

    mounted() {
        this.loadOfficersInitial();
    },

    methods: {
        async loadOfficersInitial() {
            await this.loadOfficers();

            const selectedOfficerIds = this.selection.map(officer => officer.id);
            this.selection = this.officers.filter(officer => selectedOfficerIds.includes(officer.id));
        },

        async loadOfficers() {
            const officers = await this.$http.get(`/api/portal/officers`, {
                params: {
                    q: this.query
                }
            });
            this.officers = officers.data;
        },

        removeOfficer(i) {
            this.selection.splice(i, 1);
        }
    }
};
</script>
