<template>
    <modal
        ref="modal"
        maxWidth="xl"
        :buttons="['cancel', 'save']"
        @saved="handleSaved"
        :menuItems="
            !!plan.id && [
                {
                    text: 'Delete Plan',
                    handler: deletePlan
                }
            ]
        "
    >
        <template v-slot:title>{{ plan ? 'Manage Membership Plan' : 'Create Membership Plan' }}</template>
        <template v-slot:content>
            <div class="mt-4 space-y-4">
                <div class="field-row">
                    <div class="field">
                        <label for="title" class="block text-sm font-medium text-gray-700"> Title </label>
                        <input v-model="plan.title" type="text" class="input" autocomplete="off" v-autofocus />
                    </div>

                    <div class="field">
                        <label for="type">Type</label>
                        <smart-select
                            v-model="plan.type"
                            :options="{ individual: 'Individual', business: 'Business' }"
                            nullTitle="Select"
                        />
                    </div>

                    <div class="field">
                        <label for="price">Display Position</label>
                        <input v-model="plan.position" type="number" class="input" autocomplete="off" />
                    </div>
                </div>

                <div class="field-row h-24">
                    <div class="field">
                        <label for="features" class="block text-sm font-medium text-gray-700">Summary</label>
                        <textarea v-model="plan.summary" type="text" class="input h-full" autocomplete="off" />
                    </div>

                    <div class="field">
                        <label for="features" class="block text-sm font-medium text-gray-700">Features</label>
                        <textarea v-model="plan.features" type="text" class="input h-full" autocomplete="off" />
                    </div>
                </div>

                <div class="field-row">
                    <div class="field">
                        <label for="duration">Duration</label>
                        <smart-select
                            v-model="plan.duration"
                            :options="{ recurring: 'Recurring', indefinite: 'Indefinite' }"
                            nullTitle="Select"
                        />
                    </div>
                    <div v-if="plan.duration == 'indefinite'" class="field">
                        <label for="price">One-time Price</label>
                        <input v-model="plan.priceIndefinite" type="number" class="input" autocomplete="off" />
                    </div>
                    <template v-else-if="plan.duration === 'recurring'">
                        <div class="field">
                            <label for="price">Monthly Price</label>
                            <input v-model="plan.priceMonthly" type="number" class="input" autocomplete="off" />
                        </div>

                        <div class="field">
                            <label for="price">Annual Price</label>
                            <input v-model="plan.priceYearly" type="number" class="input" autocomplete="off" />
                        </div>
                    </template>
                </div>

                <div class="field-row">
                    <div class="field title">Submembers</div>
                </div>
                <div class="field-row">
                    <div class="field">
                        <label for="includedSubmemberCount">Included</label>
                        <input
                            v-model="plan.includedSubmemberCount"
                            type="number"
                            step="1"
                            id="includedSubmemberCount"
                            class="input"
                            autocomplete="off"
                        />
                    </div>
                    <div class="field">
                        <label for="maximumSubmemberCount">Max</label>
                        <input
                            v-model="plan.maximumSubmemberCount"
                            type="number"
                            step="1"
                            class="input"
                            autocomplete="off"
                        />
                    </div>
                    <div class="field">
                        <label for="pricePerSubmember">Price/Additional</label>
                        <input v-model="plan.pricePerSubmember" type="number" class="input" autocomplete="off" />
                    </div>
                    <div class="field">
                        <label for="submemberCooldownDays">Cooldown Days</label>
                        <input v-model="plan.submemberCooldownDays" type="number" class="input" autocomplete="off" />
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer> </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';

export default {
    components: { Modal },

    data() {
        return {
            plan: null
        };
    },

    created() {
        if (!this.plan) {
            this.plan = {
                title: null,
                status: 'active',
                position: null,
                features: '',
                duration: null,
                priceIndefinite: null,
                priceMonthly: null,
                priceYearly: null,
                includedSubmemberCount: null,
                maximumSubmemberCount: null,
                pricePerSubmember: null,
                submemberCooldownDays: null
            };
        }
    },

    methods: {
        async handleSaved() {
            this.$refs.modal.mask();

            try {
                if (!this.plan.title) this.$throwUserError('Please enter a title.');
                if (!this.plan.type) this.$throwUserError('Please select a plan type.');
                if (!this.plan.duration) this.$throwUserError('Please select a plan duration.');

                if (
                    this.plan.duration === 'recurring' &&
                    !String(this.plan.priceMonthly ?? '') &&
                    !String(this.plan.priceYearly ?? '')
                ) {
                    this.$throwUserError('Please enter either a monthly or an annual price.');
                }

                if (!String(this.plan.priceMonthly)) this.plan.priceMonthly = null;
                if (!String(this.plan.priceYearly)) this.plan.priceYearly = null;

                const response = await this.$http.postOrPut('/api/portal/plans', this.plan.id, this.plan);
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save', err);
            }
        },

        async deletePlan() {
            if (!(await this.$confirm('Delete Plan', 'Are you sure you want to delete this plan?'))) return;

            this.$refs.modal.mask();

            try {
                await this.$http.delete(`/api/portal/plans/${this.plan.id}`);
                this.$dismiss(false);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Delete Plan', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.field-row {
    @apply flex-1 flex flex-row;

    .field:not(:first-of-type) {
        @apply ml-2;
    }
}
.field {
    @apply flex-1 flex flex-col;

    &.shrink {
        flex-grow: 0 !important;
    }

    label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }

    .switch-group {
        @apply flex-1;
    }

    .select-button {
        @apply w-full;
    }

    &.title {
        @apply font-bold text-sm mt-4;
    }
}
.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}
</style>
