<template>
    <div id="requests">
        <div class="top-filters">
            <h1>
                {{ $route.name }}
            </h1>
        </div>

        <RequestsTable
            :showClient="permissions['requests.client']"
            :showOfficer="permissions['requests.officer']"
            :emptyText="`You have not ${session.role === 'client' ? 'placed' : 'responded to'} any requests.`"
        />
    </div>
</template>

<script>
import RequestsTable from './requests-table';

export default {
    components: { RequestsTable },
    store: ['session', 'permissions']
};
</script>

<style lang="scss" scoped></style>
