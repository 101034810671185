<!-- This example requires Tailwind CSS v2.0+ -->
<template>
    <div class="w-full flex-1 flex flex-col px-2 sm:px-0">
        <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="pb-4 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                <div class="shadow overflow-hidden border-b border-gray-200 rounded-md sm:rounded-lg">
                    <table
                        class="min-w-full divide-y divide-gray-200"
                        :class="[$isPropTruthy(hoverable) && 'hoverable', $isPropTruthy(clickable) && 'clickable']"
                    >
                        <thead class="bg-gray-50">
                            <tr>
                                <th
                                    v-for="(column, index) in columns"
                                    :key="index"
                                    scope="col"
                                    class="header"
                                    :class="{ sortable: isSortable }"
                                    @click="setSort(index)"
                                >
                                    {{ column }}
                                    <template v-if="sortBy === index">
                                        <i v-if="sortAscending" class="fa fa-caret-down" />
                                        <i v-else class="fa fa-caret-up" />
                                    </template>
                                </th>
                                <th v-if="actionCell !== undefined" scope="col" class="relative px-6 py-3">
                                    <span class="sr-only">Edit</span>
                                </th>
                            </tr>
                        </thead>
                        <tbody class="bg-white divide-y divide-gray-200">
                            <slot name="rows"></slot>

                            <tr v-if="!data || !data.length" class="empty">
                                <td :colspan="50">
                                    <slot name="empty"></slot>
                                </td>
                            </tr>

                            <tr v-if="isLoadingComputed" class="loading">
                                <td :colspan="50" class="text-center">
                                    <i class="fad fa-spinner-third fa-spin text-5xl" />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['isLoading', 'columns', 'data', 'actionCell', 'clickable', 'hoverable', 'sortBy', 'sortAscending'],

    computed: {
        isSortable() {
            return this.sortBy !== undefined;
        },

        isLoadingComputed() {
            return this.isLoading || (this.isLoading === undefined && this.data === null);
        }
    },

    methods: {
        setSort(colIndex) {
            if (this.sortBy === colIndex) this.$emit('sort', colIndex, !this.sortAscending);
            else this.$emit('sort', colIndex, true);
        }
    }
};
</script>

<style lang="scss">
table {
    &.hoverable {
        tbody {
            tr:not(.loading):not(.empty):hover {
                @apply bg-gray-100;
            }
        }
    }

    &.clickable {
        tbody {
            tr:not(.loading):not(.empty):hover {
                cursor: pointer;
            }
        }
    }

    thead {
        th.sortable {
            cursor: pointer;
        }
    }

    .loading {
        td {
            @apply text-gray-500 font-medium tracking-wider;
        }
    }

    .header {
        @apply px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider;
    }

    td {
        @apply px-6 py-4 whitespace-nowrap text-sm;

        &.avatar-cell {
            @apply flex items-center;

            div:first-child {
                @apply mr-2;
            }
        }

        &.name {
            @apply font-semibold;
        }

        &.multiline {
            .primary {
                @apply font-medium text-gray-900;
            }
            .secondary {
                @apply font-normal text-gray-500;
            }
        }

        &.action-cell {
            @apply text-right text-sm font-medium;

            .action-link {
                @apply text-blue-600 hover:text-blue-900;
            }
        }

        &.status-cell {
            @apply px-6 py-4 whitespace-nowrap;

            .status-text {
                @apply px-2 inline-flex text-xs leading-5 font-semibold rounded-full;

                &.green {
                    @apply bg-green-100 text-green-800;
                }

                &.red {
                    @apply bg-red-100 text-red-800;
                }

                &.yellow {
                    @apply bg-yellow-100 text-yellow-800;
                }

                &.blue {
                    @apply bg-blue-100 text-blue-800;
                }

                &.gray {
                    @apply bg-gray-200 text-gray-400;
                }
            }
        }
    }
}
</style>
