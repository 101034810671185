<template>
    <div id="agencies" class="flex-1">
        <div class="top-filters">
            <h1>
                {{ $route.name }}
            </h1>
            <button type="button" class="blue" @click="editAgency()">
                <i class="far fa-plus text-lg" />
            </button>
        </div>
        <table-component
            hoverable
            clickable
            :columns="['Agency', 'Point of Contact']"
            :data="data"
            :isLoading="isLoading"
        >
            <template v-slot:rows>
                <tr v-for="(agency, i) in data" :key="i.toString()" @click="editAgency(agency)">
                    <td>{{ agency.name }}</td>
                    <td>
                        {{ agency.pocUser.firstName }}
                        {{ agency.pocUser.lastName }}
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import TableComponent from '@/common/components/c-table';

import EditModal from './agencies/m-edit-agency';

export default {
    components: { TableComponent },

    data() {
        return {
            isLoading: true,
            data: null
        };
    },

    async mounted() {
        this.load();
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/portal/agencies');
            this.data = response.data;
            this.isLoading = false;
        },

        async editAgency(agency) {
            const result = await this.$modal(EditModal, { agency });
            if (result === undefined) return;
            if (result === false) this.data.remove(agency);

            if (!agency) {
                this.data.push(result);
            } else {
                this.data.replace(agency, result);
            }
            this.data.sort((a, b) => a.name.localeCompare(b.name));
        }
    }
};
</script>
