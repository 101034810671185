<template>
    <modal ref="modal" width="md" :buttons="['cancel', 'save']" @saved="save">
        <template v-slot:title>Change Password</template>

        <template v-slot:content>
            <div class="mt-4 w-72">
                <input
                    v-model="newPassword"
                    type="text"
                    id="password"
                    ref="passwordField"
                    class="text-input"
                    autocomplete="off"
                    :readonly="shouldAutoGenerate"
                />
            </div>

            <div class="mt-4 flex">
                <div>
                    <SwitchGroup as="div" class="switch-group">
                        <Switch v-model="shouldAutoGenerate" :class="[shouldAutoGenerate && 'on', 'switch']">
                            <span class="sr-only">Auto-Generate</span>
                            <span aria-hidden="true" :class="[shouldAutoGenerate && 'on', 'thumb']" />
                        </Switch>
                    </SwitchGroup>
                </div>
                <label for="active" class="ml-2 text-sm font-medium text-gray-700">auto-generate</label>
            </div>
        </template>
    </modal>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';

import Modal from '@/common/components/c-modal';

export default {
    store: ['session'],

    components: { Modal, Switch, SwitchGroup },

    data() {
        return {
            saveUrl: null,
            newPassword: this.generateRandomPassword(),
            shouldAutoGenerate: true
        };
    },

    watch: {
        shouldAutoGenerate(value) {
            this.newPassword = value ? this.generateRandomPassword() : null;
            value || this.$nextTick(() => this.$refs.passwordField.focus());
        }
    },

    methods: {
        async save() {
            this.$refs.modal.mask();

            try {
                if (!this.newPassword) this.$throwUserError('Please enter a password.');
                if (this.newPassword.length < 8)
                    this.$throwUserError('Please create a password that is at least 8 characters in length.');

                await this.$http.put(this.saveUrl, {
                    password: this.newPassword
                });
                await this.$alert('Change Password', 'The password has been changed successfully.');
                this.$dismiss();
            } catch (e) {
                this.$alert('Change Password', e);
                this.$refs.modal.unmask();
            }
        },

        generateRandomPassword() {
            return 'Secur' + Math.round(Math.random() * 100_000) + '!';
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    @apply w-44 h-44 rounded-lg;

    :deep(i) {
        @apply text-6xl;
    }
}
</style>
