<template>
    <modal ref="modal" maxWidth="sm" :buttons="['cancel', 'save']" saveTitle="Add Credit" @saved="addCredit">
        <template v-slot:title>Add Account Credit</template>
        <template v-slot:content>
            <div>
                <div class="mt-2 mb-1">Description</div>
                <input
                    v-model="description"
                    type="text"
                    class="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                    placeholder="Description"
                    v-autofocus
                />
            </div>
            <div>
                <div class="mt-6 mb-1">Credit Amount</div>
                <input
                    v-model="amount"
                    type="number"
                    step="0.01"
                    autocomplete="noautocomplete"
                    class="focus:ring-blue-500 focus:border-blue-500 relative block w-full rounded-md bg-transparent focus:z-10 sm:text-sm border-gray-300"
                />
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';

export default {
    components: { Modal },

    data() {
        return {
            client: null,
            description: null,
            amount: 0
        };
    },

    methods: {
        async addCredit() {
            if (!this.amount || this.amount === 0)
                return this.$alert('Add Credit', 'Please enter an amount to credit the account.');

            if (
                !(await this.$confirm(
                    'Add Credit',
                    `Are you sure you want to add a credit of ${this.$filter(
                        this.amount,
                        'usCurrency'
                    )} to this client's account?`
                ))
            ) {
                return;
            }

            this.$refs.modal.mask();

            try {
                const response = await this.$http.post(`/api/portal/clients/${this.client.id}/ledger`, {
                    credit: {
                        description: this.description,
                        amount: this.amount
                    }
                });
                this.$dismiss({ balance: response.data.balance });
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Add Credit', err);
            }
        }
    }
};
</script>

<style lang="scss"></style>
