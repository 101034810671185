<template>
    <div id="officers" class="flex-1 flex flex-col">
        <div class="top-filters">
            <h1>
                {{ $route.name }}
            </h1>
            <Switcher v-model="filters.view" :options="['list', 'map']" />

            <div class="filters">
                <smart-select
                    class="status-filter"
                    v-model="filters.statusFilter"
                    :options="{ offduty: 'Off-Duty', active: 'Active', backup: 'Backup' }"
                    nullTitle="All Statuses"
                />
                <smart-select
                    v-if="isSysAdmin"
                    v-model="filters.selectedAgency"
                    nullTitle="All Agencies"
                    url="/api/portal/agencies"
                />
                <div class="search-wrap">
                    <input
                        v-model="searchText"
                        type="text"
                        class="text-input"
                        @keydown.enter="search"
                        placeholder="Search..."
                    />
                </div>
                <button type="button" class="blue" @click="editOfficer()">
                    <i class="far fa-plus text-lg" />
                </button>
            </div>
        </div>

        <table-component
            v-if="filters.view === 'list'"
            imageCell
            clickable
            hoverable
            :columns="columns"
            :data="data"
            :isLoading="isLoading"
            :sortBy="columnFields.indexOf(sort)"
            :sortAscending="sortAscending"
            @sort="sortByHeader"
        >
            <template v-slot:rows>
                <tr v-for="officer in data" :key="officer.id" @click="editOfficer(officer)">
                    <td class="indicator-cell">
                        <template v-if="officer.device">
                            <i
                                class="fa fa-circle"
                                :class="`status-${officer.officerStatus ?? 'offduty'}`"
                                v-tip="getOfficerStatusTip(officer)"
                            />
                            <i
                                v-if="officer.officerStatus && !hasCurrentLocation(officer)"
                                class="fas fa-location-circle alert"
                                v-tip="`No location reported by device in the past 24 hours`"
                            ></i>
                            <i
                                v-if="officer.appUpdateRequired"
                                class="fab fa-app-store alert"
                                v-tip="`App update is required to receive requests.`"
                            ></i>
                        </template>
                        <div v-else v-tip="`Not signed into a device.`">-</div>
                    </td>
                    <td class="avatar-cell">
                        <Avatar :person="officer" />
                        {{ officer.firstName }}
                        {{ officer.lastName }}
                    </td>
                    <td v-if="isSysAdmin">
                        {{ officer.agency?.name }}
                    </td>
                    <td class="status-cell">
                        <span :class="['status-text', officer.accessLevel === 'standard' ? 'gray' : 'blue']">{{
                            officer.accessLevel?.ucfirst()
                        }}</span>
                    </td>
                    <td class="status-cell">
                        <span :class="['status-text', officer.isActive ? 'green' : 'gray']">{{
                            officer.isActive ? 'Enabled' : 'Disabled'
                        }}</span>
                    </td>
                    <td>{{ officer.rating != 0 ? officer.rating : '-' }}</td>
                    <td v-datetime="officer.officerStatusAt" placeholder="-"></td>
                </tr>
            </template>
        </table-component>

        <Map
            v-if="filters.view === 'map'"
            class="map"
            :status="isLoading ? 'Loading...' : undefined"
            :markers="mapMarkers"
        />
    </div>
</template>

<script>
import { differenceInHours, format } from 'date-fns';

import Avatar from '@/common/components/c-avatar';
import Map from '@/common/components/c-map';
import Switcher from '@/common/components/c-switcher';
import TableComponent from '@/common/components/c-table';

import EditModal from './officers/m-edit-officer';

const STATUS_TITLES = {
    offduty: 'Off-Duty',
    active: 'Active',
    backup: 'Backup'
};

export default {
    store: ['session'],
    props: ['agency'],

    components: { Avatar, Map, Switcher, TableComponent },

    data() {
        return {
            sort: 'name',
            sortAscending: true,
            filters: {
                view: 'list',
                statusFilter: null,
                selectedAgency: null,
                activeSearch: null
            },
            searchText: null,
            isLoading: true,
            canLoadMore: true,
            data: null
        };
    },

    computed: {
        isSysAdmin() {
            return this.session.accessLevel === 'sysadmin';
        },

        columns() {
            return [
                'Status',
                'Name',
                this.isSysAdmin && 'Agency',
                'Access',
                'Enabled',
                'Rating',
                'Status Changed'
            ].filter(v => !!v);
        },

        columnFields() {
            return [
                'officerStatus',
                'name',
                this.isSysAdmin && 'agencyId',
                'accessLevel',
                'isActive',
                'rating',
                'officerStatusAt'
            ].filter(v => !!v);
        },

        mapMarkers() {
            return this.data
                ?.filter(officer => officer.device?.location)
                .map(officer => ({
                    lat: officer.device.location.y,
                    lng: officer.device.location.x,
                    title: `${officer.firstName} ${officer.lastName}`,
                    label: `${officer.firstName.substring(0, 1)}${officer.lastName.substring(0, 1)}`,
                    color: officer.officerStatus === 'active' ? '#4ade80' : '#fddf47'
                }));
        },

        loadParams() {
            return {
                q: this.filters.activeSearch ?? undefined,
                agencyId: this.filters.selectedAgency?.id,
                officerStatus: this.filters.statusFilter ?? undefined,
                sort: this.sort,
                sortDirection: this.sortAscending ? 'asc' : 'desc'
            };
        }
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    created() {
        if (this.$route.params?.agencyId) {
            this.filters.selectedAgency = {
                id: this.$route.params.agencyId,
                name: this.$route.params.agencyName
            };
        }
    },

    mounted() {
        this.load();
    },

    ancestorScrolledToBottom() {
        this.loadMore();
    },

    methods: {
        async load() {
            this.isLoading = true;
            this.data = null;

            const response = await this.$http.get('/api/portal/officers', {
                params: this.loadParams
            });
            this.data = response.data;
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        async loadMore() {
            if (this.isLoading) return;
            if (!this.canLoadMore) return;
            this.isLoading = true;
            const response = await this.$http.get('/api/portal/officers', {
                params: {
                    ...this.loadParams,
                    skip: this.data.length
                }
            });
            this.data.push(...response.data);
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        async editOfficer(officer) {
            const result = await this.$modal(EditModal, {
                defaultAgency: this.filters.selectedAgency,
                officer
            });
            if (result === undefined) return;
            if (result === false) this.data.remove(officer);

            if (!officer) {
                this.data.push(result);
            } else {
                this.data.replace(officer, result);
            }
            this.data.sort((a, b) => {
                const r = a.lastName.localeCompare(b.lastName);
                if (r !== 0) return r;
                return a.firstName.localeCompare(b.firstName);
            });
        },

        sortByHeader(index, ascending) {
            this.sort = this.columnFields[index];
            this.sortAscending = ascending;
            this.load();
        },

        hasCurrentLocation(officer) {
            if (!officer.device?.location) return false;
            if (differenceInHours(new Date(), new Date(officer.device.locationAt)) >= 24) return false;
            return true;
        },

        getOfficerStatusTip(officer) {
            const info = [
                STATUS_TITLES[officer.officerStatus ?? 'offduty'],
                ``,
                `Platform: ${officer.device.platform}`,
                `Version: ${officer.device.platformVersion ?? 'unknown'}`,
                `App Version: ${officer.device.version}`
            ];
            officer.device.locationAt &&
                info.push(`Last Location: ${format(new Date(officer.device.locationAt), 'M/d/yy h:mm:ss aaa')}`);
            return info.join('\n');
        },

        search() {
            this.filters.activeSearch = this.searchText;
        }
    }
};
</script>

<style lang="scss" scoped>
.filters > * {
    @apply ml-1;
}

.status-filter :deep(input) {
    width: 120px;
}

.status-active {
    @apply text-green-400;
}

.status-backup {
    @apply text-yellow-300;
}

.status-offduty {
    @apply text-gray-400;
}

.map {
    @apply flex-1;
}

.indicator-cell {
    i {
        @apply mr-2;
    }

    i.alert {
        @apply text-red-500;
    }
}
</style>
