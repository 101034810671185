<template>
    <modal
        ref="modal"
        maxWidth="3xl"
        :buttons="['cancel', 'save']"
        @saved="handleSaved"
        :menuItems="
            !!code.id && [
                {
                    text: 'Delete Code',
                    handler: deleteCode
                }
            ]
        "
    >
        <template v-slot:title>{{ code ? 'Manage Promo Code' : 'Create Promo Code' }}</template>
        <template v-slot:content>
            <div class="mt-4 space-y-4">
                <div class="field-row">
                    <div class="field">
                        <label for="code" class="block text-sm font-medium text-gray-700"> Code </label>
                        <input v-model="code.code" type="text" class="input" autocomplete="off" v-autofocus />
                    </div>

                    <div class="field">
                        <label for="title" class="block text-sm font-medium text-gray-700"> Title </label>
                        <input v-model="code.title" type="text" class="input" autocomplete="off" />
                    </div>

                    <div class="field">
                        <label for="price">Preload Credit</label>
                        <input
                            v-model="code.promoConfig.preloadCredit"
                            type="number"
                            class="input"
                            autocomplete="off"
                        />
                    </div>
                </div>

                <div class="field-row">
                    <div class="field title">Membership Discounts</div>
                </div>
                <div v-for="(discount, index) in code.promoConfig.membershipDiscounts" :key="index" class="field-row">
                    <div class="field">
                        <label for="includedSubmemberCount">Membership</label>
                        <smart-select
                            v-model="discount.planId"
                            url="/api/portal/plans"
                            valueKey="id"
                            titleKey="title"
                            preload
                        />
                    </div>
                    <div class="field">
                        <label for="amount">Monthly Discount</label>
                        <input v-model="discount.amountMonthly" type="number" class="input" autocomplete="off" />
                    </div>
                    <div class="field">
                        <label for="amount">Yearly Discount</label>
                        <input v-model="discount.amountYearly" type="number" class="input" autocomplete="off" />
                    </div>
                    <div class="field">
                        <label for="amount">Indefinite Discount</label>
                        <input v-model="discount.amountIndefinite" type="number" class="input" autocomplete="off" />
                    </div>
                    <div class="field">
                        <label for="amount">Trial Days</label>
                        <input v-model="discount.trialDays" type="number" class="input" autocomplete="off" />
                    </div>
                    <div class="field delete-field">
                        <label>&nbsp;</label>
                        <button @click="removeDiscount(index)" :class="{ hidden: !discount.planId }">
                            <i class="fa fa-trash" />
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:footer> </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';

export default {
    components: { Modal },

    data() {
        return {
            code: null
        };
    },

    watch: {
        'code.promoConfig.membershipDiscounts': {
            deep: true,
            handler() {
                if (this.code.promoConfig.membershipDiscounts.last()?.planId) {
                    this.code.promoConfig.membershipDiscounts.push({});
                }
            }
        }
    },

    created() {
        if (!this.code) {
            this.code = {
                code: null,
                title: null,
                promoConfig: {
                    preloadCredit: 0,
                    membershipDiscounts: []
                }
            };
        }

        this.code.promoConfig.membershipDiscounts.push({});
    },

    methods: {
        async handleSaved() {
            this.$refs.modal.mask();

            try {
                if (!this.code.code) this.$throwUserError('Please enter a code.');
                if (!this.code.title) this.$throwUserError('Please enter a title.');

                const response = await this.$http.postOrPut('/api/portal/promo-codes', this.code.id, {
                    ...this.code,
                    promoConfig: {
                        ...this.code.promoConfig,
                        membershipDiscounts: this.code.promoConfig.membershipDiscounts.filter(
                            discount =>
                                discount.planId &&
                                (discount.amountMonthly ||
                                    discount.amountYearly ||
                                    discount.amountIndefinite ||
                                    discount.trialDays)
                        )
                    }
                });
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save', err);
            }
        },

        async deleteCode() {
            if (!(await this.$confirm('Delete Code', 'Are you sure you want to delete this promo code?'))) return;

            this.$refs.modal.mask();

            try {
                await this.$http.delete(`/api/portal/promo-codes/${this.code.id}`);
                this.$dismiss(false);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Delete Code', err);
            }
        },

        removeDiscount(index) {
            this.code.promoConfig.membershipDiscounts.splice(index, 1);
        }
    }
};
</script>

<style lang="scss" scoped>
.field-row {
    @apply flex-1 flex flex-row;

    .field:not(:first-of-type) {
        @apply ml-2;
    }
}
.field {
    @apply flex-1 flex flex-col;

    &.shrink {
        flex-grow: 0 !important;
    }

    label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }

    .switch-group {
        @apply flex-1;
    }

    .select-button {
        @apply w-full;
    }

    &.title {
        @apply font-bold text-sm mt-4;
    }

    &.delete-field {
        flex-grow: 0;

        button {
            @apply px-2 flex items-center text-gray-400;
            height: 38px;

            &:hover {
                @apply text-black;
            }

            &.hidden {
                visibility: hidden;
            }
        }
    }
}
.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}
</style>
