<template>
    <div id="memberships">
        <div class="top-filters">
            <h1>Promo Codes</h1>

            <div class="filters">
                <button type="button" class="blue" @click="editCode()">
                    <i class="far fa-plus text-lg" />
                </button>
            </div>
        </div>
        <table-component
            :columns="['Code', 'Title', 'Discount']"
            :data="data"
            :isLoading="data === null || plansById === null"
            clickable
            hoverable
        >
            <template v-slot:rows>
                <tr v-for="(code, i) in data" :key="i" @click="editCode(code)">
                    <td>{{ code.code }}</td>
                    <td>{{ code.title }}</td>
                    <td>
                        <template
                            v-if="
                                !code.promoConfig ||
                                (!code.promoConfig.preloadCredit && !code.promoConfig.membershipDiscounts.length)
                            "
                            >-</template
                        >
                        <ul v-else>
                            <li v-if="code.promoConfig.preloadCredit">
                                {{ $filter(code.promoConfig.preloadCredit, 'usCurrency') }} credit preload
                            </li>
                            <template v-for="(discount, index) in code.promoConfig.membershipDiscounts" :key="index">
                                <li v-if="discount.amountMonthly">
                                    {{ $filter(discount.amountMonthly, 'usCurrency') }} off
                                    <strong>{{ plansById[discount.planId]?.title }}</strong> monthly membership
                                </li>
                                <li v-if="discount.amountYearly">
                                    {{ $filter(discount.amountYearly, 'usCurrency') }} off
                                    <strong>{{ plansById[discount.planId]?.title }}</strong> yearly membership
                                </li>
                                <li v-if="discount.amountIndefinite">
                                    {{ $filter(discount.amountIndefinite, 'usCurrency') }} off
                                    <strong>{{ plansById[discount.planId]?.title }}</strong> indefinite membership
                                </li>
                                <li v-if="discount.trialDays">
                                    {{ discount.trialDays }} day trial on
                                    <strong>{{ plansById[discount.planId]?.title }}</strong> membership
                                </li>
                            </template>
                        </ul>
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import TableComponent from '@/common/components/c-table';

import EditModal from './promo-codes/m-edit-code';

export default {
    components: {
        TableComponent
    },

    data() {
        return {
            data: null,
            plansById: null
        };
    },

    async mounted() {
        await this.loadPlans();
        await this.load();
    },

    methods: {
        async loadPlans() {
            const response = await this.$http.get('/api/portal/plans');
            this.plansById = response.data.keyBy('id');
        },

        async load() {
            this.data = null;
            const response = await this.$http.get('/api/portal/promo-codes');
            this.data = response.data;
        },

        async editCode(code) {
            const result = await this.$modal(EditModal, { code });
            if (result === undefined) return;
            this.load();
        }
    }
};
</script>
