<template>
    <div class="map-wrapper">
        <div class="map" />
        <div v-if="status" class="status">{{ status }}</div>
    </div>
</template>

<script>
import { MapHelpers } from '@/common/libs/google-maps';

export default {
    props: [
        'status',
        'options',
        'center',
        'zoom',
        'marker',
        'markers',
        'feature',
        'autoFeatureZoom',
        'drawable',
        'heatmapData'
    ],
    emits: ['drewFeature', 'ready', 'featureClicked'],

    computed: {
        effectiveCenter() {
            return (
                this.center || {
                    lat: 39.828175,
                    lng: -98.5795
                }
            );
        },

        effectiveZoom() {
            return this.zoom || 3;
        },

        currentCenter() {
            return this.effectiveCenter; //temp
        }
    },

    watch: {
        marker() {
            this.drawMarkers();
        },

        markers() {
            this.drawMarkers();
        },

        feature: {
            deep: true,
            handler() {
                this.drawFeature();
                this.$isPropTruthy(this.autoFeatureZoom) && this.zoomToFeature();
            }
        },

        heatmapData: {
            deep: true,
            handler() {
                this.drawHeatmap();
            }
        }
    },

    created() {
        this._gmarkers = [];
    },

    async mounted() {
        this.gmaps = await MapHelpers.init();

        const mapOptions = {
            center: this.effectiveCenter,
            zoom: this.effectiveZoom,
            ...(this.options || {})
        };

        let el = this.$el.querySelector('.map');
        this.map = new this.gmaps.Map(el, mapOptions);
        this.map.data.addListener('mouseover', this.handleDataMouseOver);
        this.map.data.addListener('mouseout', this.handleDataMouseOut);
        this.map.data.addListener('mouseup', this.handleDataMouseClick);
        this.$isPropTruthy(this.drawable) && this.initalizeDrawing();

        this.marker && this.drawMarkers();
        this.markers && this.drawMarkers();
        this.feature && this.drawFeature();
        this.$isPropTruthy(this.autoFeatureZoom) && this.zoomToFeature();
        this.heatmapData && this.drawHeatmap();

        this.$emit('ready');
    },

    methods: {
        initalizeDrawing() {
            this.drawingManager = new this.gmaps.drawing.DrawingManager({
                drawingMode: this.gmaps.drawing.OverlayType.POLYGON,
                drawingControl: true,
                drawingControlOptions: {
                    position: this.gmaps.ControlPosition.TOP_CENTER,
                    drawingModes: [this.gmaps.drawing.OverlayType.POLYGON]
                },
                polygonOptions: {
                    editable: true
                }
            });

            this.drawingManager.setMap(this.map);

            this.gmaps.event.addListener(this.drawingManager, 'polygoncomplete', polygon => {
                polygon.setMap(null);

                // points are in the order for GeoJSON
                const coordinates = polygon
                    .getPath()
                    .getArray()
                    .map(point => [point.lng(), point.lat()]);
                const feature = {
                    type: 'Feature',
                    geometry: {
                        type: 'Polygon',
                        coordinates: [
                            [
                                ...coordinates,
                                coordinates[0] // close the polygon
                            ]
                        ]
                    },
                    properties: {}
                };
                this.$emit('drewFeature', feature);
            });
        },

        drawMarkers() {
            this._gmarkers.forEach(marker => marker.setMap(null));
            this._gmarkers = [];

            this.marker && this._drawMarker(this.marker);
            this.markers?.forEach(this._drawMarker);
        },

        _drawMarker(marker) {
            this._gmarkers.push(
                new this.gmaps.Marker({
                    title: marker.title,
                    label: marker.label,
                    position: {
                        lat: marker.lat,
                        lng: marker.lng
                    },
                    map: this.map,
                    icon: {
                        path: this.gmaps.SymbolPath.CIRCLE,
                        scale: 12,
                        fillColor: marker.color ?? '#fff',
                        fillOpacity: 1,
                        strokeWeight: 1
                    }
                })
            );
        },

        drawFeature() {
            this.map.data.forEach(feature => this.map.data.remove(feature));
            this.feature && this.map.data.addGeoJson(this.feature);
        },

        zoomToFeature() {
            const bounds = new this.gmaps.LatLngBounds();
            this.map.data.forEach(feature => {
                feature.getGeometry().forEachLatLng(latLng => {
                    bounds.extend(latLng);
                });
            });
            this.map.fitBounds(bounds);
        },

        handleDataMouseOver(e) {
            this.handleDataMouseOut();

            const title = e.feature.getProperty('title');
            if (title) {
                this._infoWindow = new this.gmaps.InfoWindow({
                    content: title,
                    position: e.latLng,
                    pixelOffset: new this.gmaps.Size(0, -25)
                });
                this._infoWindow.open(this.map);
            }
        },

        handleDataMouseOut() {
            if (this._infoWindow) {
                this._infoWindow.close();
                this._infoWindow = null;
            }
        },

        handleDataMouseClick(e) {
            this.handleDataMouseOut();

            const featureId = e.feature?.getProperty('id');
            featureId && this.$emit('featureClicked', featureId);
        },

        drawHeatmap() {
            this._heatmap && this.clearHeatmap();
            if (!this.heatmapData || !this.heatmapData.length) return;

            this._heatmap = new this.gmaps.visualization.HeatmapLayer({
                map: this.map,
                data: this.heatmapData.map(point => new this.gmaps.LatLng(point)),
                radius: 50
            });
        },

        clearHeatmap() {
            this._heatmap.setMap(null);
            this._heatmap = null;
        }
    }
};
</script>

<style lang="scss" scoped>
.map-wrapper {
    @apply relative flex;

    .map {
        @apply flex-1;
    }

    .status {
        @apply absolute left-1/2 -translate-x-1/2 bottom-5 bg-white rounded-md px-2 py-1 border border-gray-200 shadow-sm text-sm;
    }
}
</style>
