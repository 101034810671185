<template>
    <div ref="parent" :class="['switcher button-group', { dark: dark !== undefined }]">
        <button
            v-for="(val, idx) in options"
            :key="idx"
            :class="[{ active: modelValue == val, dark: dark !== undefined }]"
            :disabled="disabled !== undefined"
            @click="handleClick(val)"
        >
            <i v-if="icons" :class="`fa fa-${icons[idx]}`" />

            <template v-else>
                {{ val.ucfirst() }}
            </template>
        </button>
    </div>
</template>

<script>
export default {
    props: ['modelValue', 'disabled', 'options', 'icons', 'dark'],

    methods: {
        handleClick(val) {
            this.$emit('update:modelValue', val);
        }
    }
};
</script>

<style lang="scss" scoped>
.switcher {
    @apply flex-shrink-0 overflow-hidden;

    > button {
        @apply flex items-center justify-center border border-gray-300 py-1 px-2;

        &.active {
            @apply bg-blue-500 text-white border-blue-500;
        }
    }
}
</style>
