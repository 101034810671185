export { generateTimeline };

const statusTextMap = {
    active: 'The call is ongoing.',
    failed: 'The call failed to connect.',
    busy: 'The line was busy.',
    'no-answer': 'There was no answer.'
};

function generateTimeline(request) {
    let events = [];

    events.push({
        type: 'update',
        content: `<strong>` + request.client.firstName.escapeHtml() + `</strong> requested protection.`,
        dateTime: request.requestAt,
        source: 'client'
    });

    request.acceptedAt &&
        events.push({
            type: 'update',
            content: `<strong>` + request.officer.firstName.escapeHtml() + `</strong> accepted the request.`,
            dateTime: request.acceptedAt,
            source: 'officer'
        });

    request.arrivedAt &&
        events.push({
            type: 'update',
            content: `<strong>` + request.officer.firstName.escapeHtml() + `</strong> indicated that they arrived.`,
            dateTime: request.arrivedAt,
            source: 'officer'
        });

    request.completedAt &&
        events.push({
            type: 'update',
            content:
                `<strong>` +
                request.officer.firstName.escapeHtml() +
                `</strong> indicated that they completed the job.`,
            dateTime: request.completedAt,
            source: 'officer'
        });

    if (request.canceledAt) {
        if (request.canceledBy == 'timeout') {
            events.push({
                type: 'update',
                content: `The request timed out after no officers accepted.`,
                dateTime: request.canceledAt,
                source: 'system'
            });
        } else if (request.canceledBy == 'csr') {
            // TODO
        } else {
            events.push({
                type: 'update',
                content:
                    `<strong>` + request[request.canceledBy].firstName.escapeHtml() + `</strong> canceled the request.`,
                dateTime: request.canceledAt,
                source: request.canceledBy
            });
        }
    }

    request.communication.forEach(commEvent => {
        const recipient = commEvent.originator === 'client' ? 'officer' : 'client';
        const action = commEvent.type === 'call' ? 'called' : 'sent a message to';
        const statusText = statusTextMap[commEvent.status] || '';

        events.push({
            type: commEvent.type,
            content:
                '<strong>' +
                request[commEvent.originator].firstName.escapeHtml() +
                '</strong> ' +
                action +
                ' <strong>' +
                request[recipient].firstName.escapeHtml() +
                '</strong>. ' +
                statusText,
            secondaryContent:
                commEvent.type === 'sms'
                    ? commEvent.smsContent
                    : commEvent.status === 'completed' && commEvent.recordingUrl,
            dateTime: commEvent.createdAt,
            source: commEvent.originator
        });
    });

    events = events.sort((a, b) => a.dateTime.localeCompare(b.dateTime));

    return events;
}
