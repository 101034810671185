<template>
    <table-component actionCell clickable hoverable :columns="columns" :data="data" :isLoading="isLoading">
        <template v-slot:empty>{{ emptyText }}</template>
        <template v-if="data" v-slot:rows>
            <tr v-for="(request, i) in data" :key="i.toString()" @click="viewRequestDetails(request)">
                <td>{{ request.id }}</td>
                <td v-if="columns.includes('Client')">
                    {{ request.client.firstName }}
                    {{ request.client.lastName }}
                </td>
                <td v-if="columns.includes('Officer')">
                    <template v-if="request.officer"
                        >{{ request.officer.firstName }} {{ request.officer.lastName }}</template
                    >
                    <template v-else>-</template>
                </td>
                <td class="multiline">
                    <div class="primary">
                        {{ extractAddress(request.requestAddress) }}
                    </div>
                    <div class="secondary">
                        {{ request.requestAddress.city }},
                        {{ request.requestAddress.state }}
                    </div>
                </td>
                <td>
                    {{ $filter(request.requestAt, 'dateTime') }}
                </td>
                <td class="status-cell">
                    <span :class="`status-text ${getStatusColor(request.status)}`">{{ request.status.ucfirst() }}</span>
                    <!-- add: pending officer report, pending client report -->
                </td>
                <td class="action-cell">
                    <i class="fas fa-chevron-right" />
                </td>
            </tr>
        </template>
    </table-component>

    <request-details
        v-if="selectedRequest"
        ref="requestDetails"
        :request-id="selectedRequest.id"
        @closed="closeRequestDetails"
    />
</template>

<script>
import TableComponent from '@/common/components/c-table';

import RequestDetails from './request-details';

const STATUS_MAP = {
    pending: 'yellow',
    accepted: 'blue',
    arrived: 'blue',
    completed: 'green',
    canceled: 'gray'
};

export default {
    components: {
        TableComponent,
        RequestDetails
    },

    props: ['emptyText', 'showClient', 'showOfficer', 'requestParams'],

    data() {
        return {
            isLoading: true,
            canLoadMore: true,
            data: null,
            selectedRequest: null
        };
    },

    computed: {
        columns() {
            return [
                'ID',
                this.showClient && 'Client',
                this.showOfficer && 'Officer',
                'Location',
                'Time',
                'Status'
            ].filter(el => el !== false);
        }
    },

    mounted() {
        this.loadRequests();
    },

    ancestorScrolledToBottom() {
        this.loadMoreRequests();
    },

    methods: {
        async loadRequests() {
            const response = await this.$http.get('/api/portal/requests', {
                params: this.requestParams
            });
            this.data = response.data;
            this.isLoading = false;
            this.canLoadMore = this.data.length > 0;
        },

        async loadMoreRequests() {
            if (this.isLoading) return;
            if (!this.canLoadMore) return;
            this.isLoading = true;
            const response = await this.$http.get('/api/portal/requests', {
                params: {
                    ...(this.requestParams || {}),
                    beforeId: this.data[this.data.length - 1].id
                }
            });
            this.data.push(...response.data);
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        getStatusColor(status) {
            return STATUS_MAP[status];
        },

        extractAddress(address) {
            const title = address.title || address.address;
            if (!title) return '';
            const cityIdx = title.indexOf(address.city);
            if (cityIdx < 0) return title;
            return title.substring(0, cityIdx).replace(/[^A-Z]+$/i, '');
        },

        // history view
        viewRequestDetails(request) {
            this.selectedRequest = request;
        },

        closeRequestDetails() {
            this.$refs.requestDetails.open = false;
            setTimeout(() => {
                this.selectedRequest = null;
            }, 500);
        }
    }
};
</script>

<style lang="scss" scoped>
table {
    &.hoverable {
        tbody {
            tr:not(.loading):hover {
                @apply cursor-pointer;
            }
        }
    }
}
</style>
