<template>
    <div id="officers">
        <table-component hoverable :columns="['Name', 'Access']" :data="data" :isLoading="isLoading">
            <template v-slot:rows>
                <tr v-for="(officer, i) in data" :key="i.toString()" @dblclick="editOfficer(officer)">
                    <td class="avatar-cell">
                        <Avatar :person="officer" />
                        {{ officer.firstName }}
                        {{ officer.lastName }}
                    </td>
                    <td class="status-cell">
                        <span :class="['status-text', officer.accessLevel === 'standard' ? 'gray' : 'blue']">{{
                            officer.accessLevel.ucfirst()
                        }}</span>
                    </td>
                    <td class="action-cell">
                        <a href="#" class="action-link" @click.prevent="editOfficer(officer)"> Edit </a>
                    </td>
                </tr>
            </template>
        </table-component>
        <button class="white" @click="navigateToOfficers">Manage Officers</button>
    </div>
</template>

<script>
import Avatar from '@/common/components/c-avatar';
import TableComponent from '@/common/components/c-table';

export default {
    props: ['agency'],

    components: {
        Avatar,
        TableComponent
    },

    data() {
        return {
            isLoading: true,
            data: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/portal/officers', {
                params: {
                    agencyId: this.agency.id
                }
            });
            this.data = response.data;
            this.isLoading = false;
        },

        editOfficer() {},

        navigateToOfficers() {
            this.$router.push({
                name: 'Officers',
                params: {
                    agencyId: this.agency.id,
                    agencyName: this.agency.name
                }
            });
            this.$emit('bye');
        }
    }
};
</script>

<style lang="scss" scoped></style>
