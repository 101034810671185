<template>
    <modal
        ref="modal"
        maxWidth="3xl"
        fullWidth
        :buttons="activeTab == 'Profile' ? ['save', 'cancel'] : ['close']"
        @saved="handleSaved"
        :menuItems="
            !!client.id && [
                {
                    text: 'Change Password...',
                    handler: showChangePassword
                },
                {
                    text: 'Send Password Reset Link',
                    handler: sendPasswordResetLink
                },
                {
                    text: 'Delete Client',
                    handler: deleteClient
                }
            ]
        "
    >
        <template v-slot:title>{{ client.id ? 'Manage Client' : 'Add Client' }}</template>
        <template v-slot:content>
            <div v-if="!isLoaded" class="flex-1 flex items-center justify-center py-12">
                <i class="fad fa-spinner-third fa-spin text-5xl" />
            </div>

            <template v-else>
                <tabs v-if="client.id" :tabs="tabs" :activeTab="activeTab" @tab-selected="tab => (activeTab = tab)" />
                <div class="mt-4 flex space-x-3">
                    <client-profile
                        ref="profile"
                        v-if="activeTab == 'Profile'"
                        :client="client"
                        @openClient="switchClient"
                    />
                    <client-requests v-if="activeTab == 'Requests'" :client="client" />
                    <client-submembers v-if="activeTab == 'Submembers'" :client="client" @openClient="switchClient" />
                    <client-membership v-if="activeTab == 'Membership'" :client="client" />
                    <client-billing v-if="activeTab == 'Billing'" :client="client" />
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';
import Tabs from '@/common/components/c-tabs';

import UpdatePasswordModal from '../shared/m-change-password';
import ClientBilling from './m-edit-client/f-billing';
import ClientMembership from './m-edit-client/f-membership';
import ClientProfile from './m-edit-client/f-profile';
import ClientRequests from './m-edit-client/f-requests';
import ClientSubmembers from './m-edit-client/f-submembers';

export default {
    store: ['session'],

    components: {
        Modal,
        Tabs,
        ClientProfile,
        ClientRequests,
        ClientSubmembers,
        ClientMembership,
        ClientBilling
    },

    data() {
        return {
            isLoaded: false,
            client: null,
            activeTab: 'Profile'
        };
    },

    computed: {
        tabs() {
            const isSysAdmin = this.session.accessLevel === 'sysadmin';
            const isSubmember = this.client.clientType === 'submember';
            const shouldShowParentTabs = isSysAdmin && !isSubmember;
            return [
                'Profile',
                shouldShowParentTabs && 'Billing',
                shouldShowParentTabs && 'Membership',
                shouldShowParentTabs && 'Submembers',
                'Requests'
            ].filter(el => !!el);
        }
    },

    created() {
        if (this.client) {
            this.load();
        } else {
            this.isLoaded = true;
            this.client = {
                accessLevel: 'standard',
                clientType: 'individual',
                email: null,
                firstName: null,
                lastName: null,
                isActive: true,
                phoneNumber: null,
                photoUuid: null,
                zip: null
            };
        }
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/portal/clients/' + this.client.id);
            this.client = response.data;
            this.isLoaded = true;
        },

        async handleSaved() {
            this.$refs.modal.mask();
            try {
                const result = await this.$refs.profile.save();
                this.$dismiss(result);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save', err);
            }
        },

        async deleteClient() {
            if (!(await this.$confirm('Delete Client', 'Are you sure you want to delete this client?'))) {
                return;
            }

            try {
                await this.$http.delete('/api/portal/clients/' + this.client.id);
                this.$dismiss(false);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Delete Client', err);
            }
        },

        async showChangePassword() {
            this.$modal(UpdatePasswordModal, {
                saveUrl: '/api/portal/clients/' + this.client.id
            });
        },

        async sendPasswordResetLink() {
            if (!(await this.$confirm('Password Reset Link', 'Are you sure you want to send a password reset link?')))
                return;

            try {
                this.$refs.modal.mask();
                await this.$http.post('/api/auth/forgot', { email: this.client.email, role: 'client' });
                await this.$alert('Password Reset Link', 'The password reset link has been sent.');
            } catch (err) {
                this.$alert('Reset Password', err);
            } finally {
                this.$refs.modal.unmask();
            }
        },

        switchClient(client) {
            this.activeTab = 'Profile';
            this.isLoaded = false;
            this.client = client;
            this.load();
        }
    }
};
</script>

<style lang="scss" scoped></style>
