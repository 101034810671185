<template>
    <modal ref="modal" :buttons="['cancel', 'save']" saveTitle="Save" @saved="handleSaved">
        <template v-slot:title>Update Card</template>
        <template v-slot:content>
            <div class="mt-4 border-gray-300 border p-2 rounded-md w-64">
                <div ref="cardElement">Loading...</div>
            </div>
        </template>
    </modal>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

import Modal from '@/common/components/c-modal';

export default {
    props: ['user'],
    components: { Modal },

    data() {
        return {};
    },

    async mounted() {
        const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
        const elements = stripe.elements();
        const cardElement = elements.create('card');
        cardElement.mount(this.$refs.cardElement);
        cardElement.once('ready', () => cardElement.focus());

        this._stripe = stripe;
        this._cardElement = cardElement;
    },

    methods: {
        async handleSaved() {
            this.$refs.modal.mask();

            try {
                const stripeResult = await this._stripe.createPaymentMethod({
                    type: 'card',
                    card: this._cardElement
                });

                if (stripeResult.error) {
                    this.$throwUserError(stripeResult.error.message.replace('Your', 'The'));
                }

                const response = await this.$http.put(`/api/portal/clients/${this.client.id}`, {
                    stripePaymentMethodId: stripeResult.paymentMethod.id
                });

                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save Card', err);
            }
        }
    }
};
</script>

<style lang="scss"></style>
