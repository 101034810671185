<template>
    <div id="app">
        <div v-if="globalError" class="root-status global-error" v-text="globalError" />
        <template v-else-if="$route.matched.length">
            <embed-plans v-if="$route.name === 'PlansEmbed'" />
            <embed-signup v-else-if="$route.name === 'SignupEmbed'" />
            <embed-gift-cards v-else-if="$route.name === 'GiftCardsEmbed'" />
            <reset-password v-else-if="$route.name === 'ResetPassword'" />

            <login v-else-if="!session" />

            <layout v-else>
                <router-view />
            </layout>
        </template>

        <!-- this is necessary to render the root injections (modals) -->
        <component v-for="(injection, index) in rootInjections" :key="index" :is="injection" />
    </div>
</template>

<script>
import * as Sentry from '@sentry/vue';

import Layout from '@/common/components/f-layout';
import { computePermissions } from '@/permissions';
import EmbedGiftCards from '@/views/embed/gift-cards';
import EmbedPlans from '@/views/embed/plans';
import EmbedSignup from '@/views/embed/signup';
import Login from '@/views/portal/login';
import ResetPassword from '@/views/portal/reset-password';

export default {
    store: ['session', 'globalError', 'rootInjections'],
    components: { EmbedGiftCards, EmbedPlans, EmbedSignup, Login, ResetPassword, Layout },

    data() {
        return {
            store: {
                permissions: null
            }
        };
    },

    watch: {
        session() {
            this.store.permissions = computePermissions(this.session);
            this.updateSentryScope();
        }
    },

    methods: {
        updateSentryScope() {
            const resolvedUser = this.session
                ? {
                      id: this.session.id,
                      email: this.session.email
                  }
                : null;
            Sentry.configureScope(function (scope) {
                scope.setUser(resolvedUser);
            });
        }
    }
};
</script>

<style lang="scss">
@import './common/styles/common';

#app {
    @apply flex-1 flex;
    color: #2c3e50;
}

#app > .root-status {
    @apply flex-1 flex justify-center items-center bg-black text-white;
}

#app > .global-error {
    @apply text-2xl text-red-300;
}
</style>
