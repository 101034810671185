import { difference, union } from '@turf/turf';

export class GeoJSONTools {
    static addFeatures(feature, newFeatures) {
        newFeatures.forEach(newFeature => this.mergeFeature(feature, newFeature));
    }

    static mergeFeature(feature, newFeature) {
        if (feature.geometry) feature.geometry = union(feature, newFeature).geometry;
        else feature.geometry = newFeature.geometry;
    }

    static removeFeatures(feature, removeFeatures) {
        removeFeatures.forEach(removeFeature => this.extractFeature(feature, removeFeature));
    }

    static extractFeature(feature, extractFeature) {
        if (!feature.geometry) return;
        const result = difference(feature, extractFeature);
        feature.geometry = result ? result.geometry : null;
    }
}
