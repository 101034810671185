<template>
    <TransitionRoot as="template" :show="shouldShow">
        <Dialog as="div" static class="fixed inset-0 overflow-hidden z-10" :open="shouldShow">
            <div class="absolute inset-0 overflow-hidden">
                <TransitionChild
                    as="template"
                    enter="ease-in-out duration-500"
                    enter-from="opacity-0"
                    enter-to="opacity-100"
                    leave="ease-in-out duration-500"
                    leave-from="opacity-100"
                    leave-to="opacity-0"
                >
                    <DialogOverlay class="overlay" @click="close" />
                </TransitionChild>

                <div class="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                    <TransitionChild
                        as="template"
                        enter="transform transition ease-in-out duration-500 sm:duration-500"
                        enter-from="translate-x-full"
                        enter-to="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-500"
                        leave-from="translate-x-0"
                        leave-to="translate-x-full"
                    >
                        <div class="w-screen max-w-md">
                            <div class="h-full flex flex-col py-6 pb-0 bg-white shadow-xl">
                                <div class="px-4 sm:px-6">
                                    <div class="flex flex-1 min-h-0 items-center justify-between">
                                        <DialogTitle class="text-lg font-medium text-gray-900">
                                            {{ title }}
                                        </DialogTitle>
                                        <div class="ml-3 flex items-center">
                                            <button class="close-button" @click="close">
                                                <span class="sr-only">Close panel</span>
                                                <i class="fal fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-6 relative flex flex-col flex-1 min-h-0">
                                    <slot />
                                </div>
                            </div>
                        </div>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script>
import { Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue';

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot
    },

    props: ['title'],

    data() {
        return {
            shouldShow: false
        };
    },

    mounted() {
        this.shouldShow = true;
    },

    methods: {
        close() {
            this.shouldShow = false;
            this.$emit('close');
        }
    }
};
</script>
