<template>
    <div class="flex justify-end z-50">
        <Menu as="div" class="relative">
            <MenuButton class="focus:outline-none rounded-md hover:bg-gray-100 p-1">
                <ChevronDownIcon class="w-5 h-5" />
            </MenuButton>

            <transition
                enter-active-class="transition transform duration-100 ease-out"
                enter-from-class="opacity-0 scale-90"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="transition transform duration-100 ease-in"
                leave-from-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-90"
            >
                <MenuItems
                    class="origin-top-right mt-2 focus:outline-none absolute right-0 bg-white overflow-hidden rounded-md shadow-lg border"
                >
                    <MenuItem v-for="(item, index) in items" :key="index">
                        <a
                            @click="item.handler()"
                            class="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 text-right cursor-pointer whitespace-nowrap"
                        >
                            {{ item.text }}
                        </a>
                    </MenuItem>
                </MenuItems>
            </transition>
        </Menu>
    </div>
</template>

<script>
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/vue';
import { ChevronDownIcon } from '@heroicons/vue/solid';

export default {
    props: ['items'],

    components: {
        Menu,
        MenuButton,
        MenuItem,
        MenuItems,
        ChevronDownIcon
    }
};
</script>
