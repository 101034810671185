<template>
    <div class="flex-1 flex items-center justify-center bg-black py-12 px-4 sm:px-6 lg:px-8">
        <div v-if="isLoading">
            <i class="fad fa-spinner-third fa-spin text-6xl text-white" />
        </div>
        <div v-else class="max-w-md w-full space-y-8">
            <div>
                <img class="mx-auto h-16 w-auto" src="/assets/secur-wordmark.png" alt="Secur" />
            </div>
            <form class="mt-8 space-y-6">
                <div v-if="error" class="error" v-text="error"></div>

                <div class="rounded-md shadow-sm space-y-3">
                    <div class="rounded-md">
                        <label for="new-password" class="sr-only">New Password</label>
                        <input
                            v-model="newPassword"
                            id="new-password"
                            type="password"
                            v-autofocus
                            required
                            class="input"
                            placeholder="New Password"
                        />
                    </div>
                    <div>
                        <label for="confirm-password" class="sr-only">Confirm Password</label>
                        <input
                            v-model="confirmPassword"
                            id="confirm-password"
                            type="password"
                            required
                            class="input"
                            placeholder="Confirm Password"
                        />
                    </div>
                </div>

                <div>
                    <button
                        @click.prevent="reset"
                        type="submit"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <i class="fa fa-lock text-red-400 group-hover:text-red-400" />
                        </span>
                        Reset Password
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    store: ['session', 'globalError'],

    data() {
        return {
            isLoading: true,
            newPassword: null,
            confirmPassword: null,
            error: null
        };
    },

    mounted() {
        this.checkKey();
    },

    methods: {
        async checkKey() {
            try {
                await this.$http.post('/api/auth/reset', {
                    action: 'verify',
                    token: this.$route.params.token
                });
                this.isLoading = false;
            } catch (err) {
                if (err.code === 'USERERR') {
                    this.globalError = err.message;
                } else {
                    this.$reportError(err);
                    this.globalError = 'An unexpected error was encountered. Please try again.';
                }
            }
        },

        async reset() {
            this.error = null;
            this.$maskForm();

            try {
                if (!this.newPassword || !this.confirmPassword)
                    this.$throwUserError('Please create a new password and confirm it.');
                if (this.newPassword.length < 8)
                    this.$throwUserError('Your new password must be at least 8 characters long.');
                if (this.newPassword !== this.confirmPassword)
                    this.$throwUserError('The passwords you entered do not match.');

                const response = await this.$http.post('/api/auth/reset', {
                    action: 'reset',
                    token: this.$route.params.token,
                    password: this.newPassword
                });

                await this.$alert(
                    'Reset Password',
                    `You have successfully ${response.data.isFirstTime ? 'set' : 'reset'} your password.`
                );

                const targetApp =
                    this.$route.params.role || (navigator.userAgent.includes('Mobile') && response.data.role);

                if (targetApp === 'client') {
                    location.href = process.env.VUE_APP_CLIENT_APP_URL;
                } else if (targetApp === 'officer') {
                    location.href = process.env.VUE_APP_OFFICER_APP_URL;
                } else {
                    this.$router.replace('/');
                }
            } catch (err) {
                this.$unmaskForm();

                if (err.code === 'USERERR') {
                    this.error = err.message;
                } else {
                    this.$reportError(err);
                    this.error = 'An unexpected error was encountered. Please try again.';
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.input {
    @apply appearance-none
        relative
        block
        w-full
        px-3
        py-2
        border border-gray-300
        placeholder-gray-500
        text-gray-900
        rounded-md
        focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10
        sm:text-sm;
}
.error {
    @apply rounded-md p-2 bg-red-200 text-red-900;
}
</style>
