<template>
    <div class="flex-1 flex items-center justify-center bg-black py-12 px-4 sm:px-6 lg:px-8">
        <div v-if="isLoading">
            <i class="fad fa-spinner-third fa-spin text-6xl text-white" />
        </div>
        <div v-else class="max-w-md w-full space-y-8">
            <div>
                <img class="mx-auto h-16 w-auto" src="/assets/secur-wordmark.png" alt="Secur" />
            </div>
            <form class="mt-8 space-y-6">
                <div v-if="error" class="error" v-text="error"></div>

                <div class="rounded-md shadow-sm -space-y-px">
                    <div class="mb-5">
                        <div class="bg-gray-700 p-1 rounded-md">
                            <nav class="flex space-x-4 justify-center items-center" aria-label="Tabs">
                                <a
                                    v-for="role in roles"
                                    :key="role"
                                    :class="[
                                        role === selectedRole
                                            ? 'bg-red-700 text-white'
                                            : 'text-gray-300 hover:text-gray-400',
                                        'px-3 py-1 font-medium text-sm rounded-md flex-1 text-center cursor-pointer'
                                    ]"
                                    @click.prevent="selectedRole = role"
                                >
                                    {{ role.ucfirst() }}
                                </a>
                            </nav>
                        </div>
                    </div>

                    <div>
                        <label for="email-address" class="sr-only">Email address</label>
                        <input
                            v-model="email"
                            id="email-address"
                            type="email"
                            v-autofocus
                            autocomplete="email"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-red-500 focus:border-red-500 focus:z-10 sm:text-sm"
                            placeholder="Email address"
                        />
                    </div>
                    <div class="relative">
                        <label for="password" class="sr-only">Password</label>
                        <input
                            v-model="password"
                            id="password"
                            type="password"
                            autocomplete="current-password"
                            required=""
                            class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-red-500 focus:border-red-400 focus:z-10 sm:text-sm"
                            placeholder="Password"
                        />

                        <a href="#" class="forgot-link" @click.prevent="forgotPassword"> forgot? </a>
                    </div>
                </div>

                <!-- <div class="flex items-center justify-between">
                    <div class="flex items-center">
                        <input
                            id="remember_me"
                            type="checkbox"
                            class="h-4 w-4 text-red-500 focus:ring-red-400 border-gray-300 rounded"
                        />
                        <label for="remember_me" class="ml-2 block text-sm text-gray-300"> Remember me </label>
                    </div>

                    <div class="text-sm">
                        <a href="#" class="font-medium text-red-500 hover:text-red-400" @click.prevent="forgotPassword"> Forgot your password? </a>
                    </div>
                </div> -->

                <div>
                    <button
                        @click.prevent="login"
                        type="submit"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                        <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                            <i class="fa fa-lock text-red-400 group-hover:text-red-400" />
                        </span>
                        Sign in
                    </button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    store: ['session', 'globalError'],

    data() {
        return {
            isLoading: true,
            roles: ['client', 'officer'],
            selectedRole: 'client',
            email: null,
            password: null,
            error: null
        };
    },

    mounted() {
        this.checkSession();
    },

    methods: {
        async checkSession() {
            try {
                const response = await this.$http.get('/api/auth/me');
                this.session = response.data;
            } catch (err) {
                // if we're not authenticated, we'll just show the login
                if (err.response && err.response.status === 401) {
                    this.isLoading = false;
                    return;
                }

                // otherwise, show an error
                this.$reportError(err);
                this.globalError = 'There was an error initializing the application. Please try again.';
            }
        },

        async login() {
            this.error = null;
            this.$maskForm();

            try {
                if (!this.email || !this.password) this.$throwUserError('Please enter your email and password.');

                const loginResponse = await this.$http.post('/api/auth/login', {
                    role: this.selectedRole,
                    email: this.email,
                    password: this.password
                });
                localStorage.securSessionJwt = loginResponse.data.jwt;

                const infoResponse = await this.$http.get('/api/auth/me');
                this.session = infoResponse.data;
            } catch (err) {
                this.$unmaskForm();

                if (err.code === 'USERERR') {
                    this.error = err.message;
                } else {
                    this.$reportError(err);
                    this.error = 'An unexpected error was encountered. Please try again.';
                }
            }
        },

        async forgotPassword() {
            this.error = null;
            this.$maskForm();

            try {
                if (!this.email) this.$throwUserError('Please enter your e-mail address.');

                await this.$http.post('/api/auth/forgot', {
                    role: this.selectedRole,
                    email: this.email
                });

                this.$unmaskForm();
                this.error =
                    'We emailed you a link to reset your password. Please allow a few minutes for it to arrive.';
            } catch (err) {
                this.$unmaskForm();

                if (err.code === 'USERERR') {
                    this.error = err.message;
                } else {
                    this.$reportError(err);
                    this.error = 'An unexpected error was encountered. Please try again.';
                }
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.error {
    @apply rounded-md p-2 bg-red-200 text-red-900;
}

.forgot-link {
    @apply absolute right-2 top-2 text-sm font-medium text-red-600 hover:text-red-400;
}
</style>
