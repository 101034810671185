<template>
    <modal
        ref="modal"
        maxWidth="xl"
        fullWidth
        :buttons="!!isLoaded && [sr.status !== 'canceled' && 'delete', 'cancel', 'save']"
        :buttonText="{
            delete: ['pending', 'quoted'].includes(sr.status) ? 'Decline' : 'Cancel',
            save: sr.status === 'pending' ? 'Respond' : 'Save',
            cancel: 'Close'
        }"
        @saved="handleSaved"
        @removed="handleRemoved"
    >
        <template v-slot:title> Scheduled Request #{{ sr.id }} </template>

        <template v-slot:content>
            <loader v-if="!isLoaded" />
            <template v-else>
                <tabs
                    :tabs="['Overview', showDispatch && 'Dispatch']"
                    :activeTab="activeTab"
                    @tab-selected="tab => (activeTab = tab)"
                />
                <div class="mt-4 flex flex-1">
                    <overview v-if="activeTab === 'Overview'" ref="activeTab" :modal="$refs.modal" :sr="sr" />
                    <dispatch v-else-if="activeTab === 'Dispatch'" ref="activeTab" :modal="$refs.modal" :sr="sr" />
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
import Loader from '@/common/components/c-loader';
import Modal from '@/common/components/c-modal';
import Tabs from '@/common/components/c-tabs';

import Dispatch from './m-prescheduled-detail/f-dispatch';
import Overview from './m-prescheduled-detail/f-overview';

export default {
    store: ['session'],

    components: {
        Modal,
        Tabs,
        Overview,
        Dispatch,
        Loader
    },

    data() {
        return {
            isLoaded: false,
            sr: null,
            activeTab: 'Overview'
        };
    },

    computed: {
        showDispatch() {
            return this.sr.status === 'canceled'
                ? this.sr.precancelStatus === 'accepted'
                : !['pending', 'quoted'].includes(this.sr.status);
        }
    },

    async mounted() {
        // temp
        this.$refs.modal.$dismiss = this.$dismiss;

        try {
            const response = await this.$http.get(`/api/portal/prescheduled/${this.sr.id}`);
            this.sr = response.data;
            this.isLoaded = true;
        } catch (err) {
            this.$alert(err);
            this.$dismiss();
        }
    },

    methods: {
        handleSaved() {
            this.$refs.activeTab.handleSaved();
        },

        handleRemoved() {
            this.$refs.activeTab.handleRemoved();
        }
    }
};
</script>

<style scoped lang="scss">
.field {
    @apply flex-1;
}
.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}
</style>
