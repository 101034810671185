<template>
    <modal
        ref="modal"
        maxWidth="lg"
        :buttons="['cancel', 'save']"
        @saved="handleSaved"
        :menuItems="
            !!officer.id && [
                {
                    text: 'Change Password...',
                    handler: showChangePassword
                },
                {
                    text: 'Send Password Reset Link',
                    handler: sendPasswordResetLink
                },
                {
                    text: 'Delete Officer',
                    handler: deleteOfficer
                }
            ]
        "
    >
        <template v-slot:title>
            {{ officer.id ? 'Manage Officer' : 'Add Officer' }}
        </template>

        <template v-slot:content>
            <div v-if="!isLoaded" class="flex-1 flex items-center justify-center py-12">
                <i class="fad fa-spinner-third fa-spin text-5xl" />
            </div>

            <template v-else>
                <tabs
                    v-if="officer.id"
                    :tabs="['Profile', 'Requests']"
                    :activeTab="activeTab"
                    @tab-selected="tab => (activeTab = tab)"
                />
                <div class="mt-4 flex space-x-3">
                    <profile v-if="activeTab === 'Profile'" v-model:officer="officer" />
                    <requests v-if="activeTab === 'Requests'" :officer="officer" />
                </div>
            </template>
        </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';
import Tabs from '@/common/components/c-tabs';

import UpdatePasswordModal from '../shared/m-change-password';
import Profile from './m-edit-officer/f-profile';
import Requests from './m-edit-officer/f-requests';

export default {
    store: ['session'],

    components: {
        Modal,
        Tabs,
        Profile,
        Requests
    },

    data() {
        return {
            isLoaded: false,
            defaultAgency: null,
            officer: null,
            activeTab: 'Profile'
        };
    },

    created() {
        if (this.officer) {
            this.load();
        } else {
            this.isLoaded = true;
            this.officer = {
                agency: this.defaultAgency,
                accessLevel: 'standard',
                email: null,
                firstName: null,
                lastName: null,
                isActive: true,
                officerPlainclothesEnabled: false,
                phoneNumber: null,
                photoUuid: null,
                licenseNumber: null
            };
        }
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/portal/officers/' + this.officer.id);
            this.officer = response.data;
            this.isLoaded = true;
        },

        async handleSaved() {
            this.$refs.modal.mask();

            try {
                if (this.session.accessLevel === 'sysadmin' && !this.officer.agency)
                    this.$throwUserError('Please select an agency.');
                if (!this.officer.firstName) this.$throwUserError('Please enter a first name.');
                if (!this.officer.lastName) this.$throwUserError('Please enter a last name.');
                if (!this.officer.email) this.$throwUserError('Please enter an email address.');

                const response = await this.$http.postOrPut('/api/portal/officers', this.officer.id, this.officer);
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save', err);
            }
        },

        async deleteOfficer() {
            if (!(await this.$confirm('Delete Officer', 'Are you sure you want to delete this officer?'))) {
                return;
            }

            try {
                await this.$http.delete('/api/portal/officers/' + this.officer.id);
                this.$dismiss(false);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Delete Officer', err);
            }
        },

        async showChangePassword() {
            this.$modal(UpdatePasswordModal, {
                saveUrl: '/api/portal/officers/' + this.officer.id
            });
        },

        async sendPasswordResetLink() {
            if (!(await this.$confirm('Password Reset Link', 'Are you sure you want to send a password reset link?')))
                return;

            try {
                this.$refs.modal.mask();
                await this.$http.post('/api/auth/forgot', { email: this.officer.email, role: 'officer' });
                await this.$alert('Password Reset Link', 'The password reset link has been sent.');
            } catch (err) {
                this.$alert('Reset Password', err);
            } finally {
                this.$refs.modal.unmask();
            }
        }
    }
};
</script>

<style scoped lang="scss">
.field {
    @apply flex-1;
}
.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}
</style>
