<template>
    <div id="billing">
        <div class="flex">
            <div class="flex-1">
                <label>Credit Card</label>
                <div v-if="client.stripeCardPlain" class="info-block">
                    <div class="card-number-wrap">
                        <i :class="`fab fa-cc-${cardIssuer} text-2xl mr-2`" />
                        <span class="card-number">
                            {{ client.stripeCardPlain.substring(1) }}, exp {{ client.stripeCardExp.substring(2, 4) }}/{{
                                client.stripeCardExp.substring(0, 2)
                            }}
                        </span>
                    </div>
                    <button type="button" class="white ml-4" @click="showCardModal">Change</button>
                </div>
                <div v-else class="info-block">
                    <div class="card-number-wrap">
                        <i class="fa fa-credit-card text-2xl mr-2" />
                        <span class="card-number"> No card on file </span>
                    </div>
                    <button type="button" class="white ml-4" @click="showCardModal">Add</button>
                </div>
            </div>

            <div class="flex-1 ml-2">
                <label>Balance</label>
                <div class="info-block">
                    {{ $filter(client.balance, 'usCurrency') }}
                    <button type="button" class="white ml-4" @click="showCreditModal">Add Credit</button>
                </div>
            </div>
        </div>

        <label class="ml-1 mb-4">Ledger</label>
        <table-component
            class="ledger"
            actionCell
            hoverable
            :columns="['Date/Time', 'Description', 'Amount', 'Status']"
            :data="ledger"
            :isLoading="ledger === null"
        >
            <template v-slot:empty>No transactions.</template>
            <template v-slot:rows>
                <tr v-for="(charge, i) in ledger" :key="i">
                    <td v-datetime="charge.createdAt" />
                    <td>
                        <template v-if="charge.parentEntryId">&mdash; </template>
                        <template v-if="charge.description">{{ charge.description }}</template>
                        <template v-else-if="charge.amount < 0"
                            >Refund to {{ charge.stripePaymentId ? 'card' : 'balance' }}</template
                        >
                        <!-- this next one is a bit confusing, because balance works in the reverse of the amount column. we should change this. -->
                        <template v-else-if="charge.balanceAmount < 0">Credit to balance</template>
                        <template v-else>Payment from {{ charge.stripePaymentId ? 'card' : 'balance' }}</template>
                    </td>
                    <td>
                        <div>
                            {{ $filter(charge.amount, 'usCurrency') }}
                            <span v-if="charge.balanceAmount > 0 && !charge.stripePaymentId" class="text-gray-400">
                                ({{ $filter(charge.balanceAmount, 'usCurrency') }})
                            </span>
                        </div>
                        <div v-if="charge.taxAmount" class="text-xs text-gray-400">
                            + tax: {{ $filter(charge.taxAmount, 'usCurrency') }}
                        </div>
                    </td>
                    <td>
                        <template v-if="charge.status === 'charged' && charge.amount > 0">Credited</template>
                        <template v-else-if="charge.status">{{ $filter(charge.status, 'ucfirst') }}</template>
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import TableComponent from '@/common/components/c-table';

import AddCreditModal from './f-billing/m-add-credit';
import EditCardModal from './f-billing/m-edit-card';

const CARD_ISSUER_MAP = {
    V: 'visa',
    M: 'mastercard',
    D: 'discover',
    A: 'amex'
};

export default {
    props: ['client'],

    components: {
        TableComponent
    },

    data() {
        return {
            ledger: null
        };
    },

    computed: {
        cardIssuer() {
            return this.client.stripeCardPlain ? CARD_ISSUER_MAP[this.client.stripeCardPlain.substring(0, 1)] : null;
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.ledger = null;
            const response = await this.$http.get(`/api/portal/clients/${this.client.id}/ledger`);
            this.ledger = response.data;
        },

        async showCardModal() {
            const result = await this.$modal(EditCardModal, { client: this.client });
            if (result) Object.assign(this.client, result);
        },

        async showCreditModal() {
            const result = await this.$modal(AddCreditModal, { client: this.client });
            if (result) {
                Object.assign(this.client, result);
                this.load();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
#billing {
    @apply w-full;

    label {
        @apply block text-sm font-medium text-gray-500;
    }

    .info-block {
        @apply border border-gray-300 rounded-md mb-3 p-4 flex items-center justify-between mt-2;
        height: 66px;

        .card-number-wrap {
            @apply flex-1 flex items-center;

            i {
                &[class*='visa'] {
                    color: #0157a2;
                }

                &[class*='discover'] {
                    color: #f68121;
                }

                &[class*='mastercard'] {
                    color: #0a3a82;
                }

                &[class*='amex'] {
                    color: #007bc1;
                }
            }
        }
    }

    .ledger {
        @apply overflow-auto;
        max-height: 50vh;
    }
}
</style>
