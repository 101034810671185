<template>
    <div id="requests">
        <RequestsTable
            :requestParams="{
                agencyId: agency.id
            }"
            :showClient="true"
            :showOfficer="true"
            emptyText="This agency has not responded to any requests."
        />
    </div>
</template>

<script>
import RequestsTable from '../../requests/requests-table';

export default {
    props: ['agency'],

    components: { RequestsTable },

    mounted() {
        this.$el.parentElement.parentElement.parentElement.style.maxWidth = 'none';
        this.$el.parentElement.parentElement.parentElement.style.width = 'auto';
    },

    beforeUnmount() {
        this.$el.parentElement.parentElement.parentElement.style.removeProperty('max-width');
        this.$el.parentElement.parentElement.parentElement.style.removeProperty('width');
    }
};
</script>

<style lang="scss" scoped></style>
