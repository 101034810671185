<template>
    <div id="clients" class="flex-1 flex flex-col">
        <div class="top-filters">
            <h1 class="text-2xl font-semibold text-gray-900">
                {{ isSysAdmin ? 'Clients' : 'Employees' }}
            </h1>
            <Switcher v-if="isSysAdmin" v-model="view" :options="['list', 'map']" />

            <div class="filters">
                <smart-select
                    class="type"
                    v-model="filters.clientType"
                    :options="{ individual: 'Individual', business: 'Business', submember: 'Submember' }"
                    nullTitle="All Types"
                />
                <smart-select
                    class="membership"
                    v-model="filters.membershipPlanId"
                    url="/api/portal/plans"
                    valueKey="id"
                    titleKey="title"
                    nullTitle="All Users"
                    :prependOptions="[
                        { id: 'none', title: 'No Membership' },
                        { id: 'any', title: 'Any Membership' }
                    ]"
                />
                <div class="search-wrap">
                    <input
                        v-model="searchText"
                        type="text"
                        class="text-input"
                        @keydown.enter="search"
                        placeholder="Search..."
                    />
                </div>
                <button type="button" class="blue" @click="editClient()">
                    <i class="far fa-plus text-lg" />
                </button>
            </div>
        </div>

        <table-component
            v-if="view === 'list'"
            clickable
            hoverable
            :columns="columns"
            :data="data"
            :isLoading="isLoading"
            :sortBy="columnFields.indexOf(sort)"
            :sortAscending="sortAscending"
            @sort="sortByHeader"
        >
            <template v-slot:rows>
                <tr v-for="client in data" :key="client.id" @click="editClient(client)">
                    <td class="avatar-cell">
                        <Avatar :person="client" />
                        <div>
                            <p>
                                {{ client.firstName }}
                                {{ client.lastName }}
                            </p>
                            <p v-if="client.company" class="company-name">
                                {{ client.company }}
                            </p>
                        </div>
                    </td>
                    <td>{{ client.email || '-' }}</td>
                    <td v-if="isSysAdmin" class="status-cell">
                        <span :class="['status-text', 'gray']">{{ client.clientType.ucfirst() }}</span>
                    </td>
                    <td class="status-cell">
                        <span :class="['status-text', client.accessLevel === 'standard' ? 'gray' : 'blue']">{{
                            client.accessLevel?.ucfirst()
                        }}</span>
                    </td>
                    <td v-if="isSysAdmin">
                        {{ client.membership?.plan.title || '-' }}
                    </td>
                    <td v-if="isSysAdmin">
                        {{ client.zip || '-' }}
                    </td>
                    <td>{{ client.rating != 0 ? client.rating : '-' }}</td>
                    <td v-if="isSysAdmin" v-datetime="client.createdAt"></td>
                    <td v-datetime="client.lastLoginAt" placeholder="-"></td>
                </tr>
            </template>
        </table-component>

        <Map v-if="view === 'map'" class="map" :status="data && 'Loading...'" :heatmap-data="heatmapData" />
    </div>
</template>

<script>
import Avatar from '@/common/components/c-avatar';
import Map from '@/common/components/c-map';
import Switcher from '@/common/components/c-switcher';
import TableComponent from '@/common/components/c-table';
import { MapHelpers } from '@/common/libs/google-maps';

import EditModal from './clients/m-edit-client';

export default {
    store: ['session'],
    props: ['agency'],

    components: { Avatar, Map, Switcher, TableComponent },

    data() {
        return {
            view: 'list',
            searchText: null,
            filters: {
                clientType: null,
                membershipPlanId: null,
                activeSearch: null
            },
            sort: 'name',
            sortAscending: true,
            isLoading: true,
            canLoadMore: true,
            data: null
        };
    },

    computed: {
        isSysAdmin() {
            return this.session.accessLevel === 'sysadmin';
        },

        columns() {
            return this.isSysAdmin
                ? ['Name', 'Email', 'Type', 'Access', 'Membership', 'ZIP', 'Rating', 'Signup At', 'Last Login']
                : ['Name', 'Email', 'Access', 'Rating', 'Last Login'];
        },

        columnFields() {
            return this.isSysAdmin
                ? [
                      'name',
                      'email',
                      'clientType',
                      'accessLevel',
                      'membership',
                      'zip',
                      'rating',
                      'createdAt',
                      'lastLoginAt'
                  ]
                : ['name', 'email', 'accessLevel', 'rating', 'lastLoginAt'];
        },

        heatmapData() {
            return this.data
                .filter(client => client.zipGeo)
                .map(client => ({ lat: client.zipGeo.y, lng: client.zipGeo.x }));
        },

        loadParams() {
            return {
                clientType: this.filters.clientType ?? undefined,
                membershipPlanId: this.filters.membershipPlanId ?? undefined,
                q: this.filters.activeSearch ?? undefined,
                sort: this.sort,
                sortDirection: this.sortAscending ? 'asc' : 'desc'
            };
        }
    },

    watch: {
        filters: {
            deep: true,
            handler() {
                this.load();
            }
        }
    },

    mounted() {
        MapHelpers.init();
        this.load();
    },

    ancestorScrolledToBottom() {
        this.loadMore();
    },

    methods: {
        async load() {
            this.isLoading = true;
            this.data = null;

            const response = await this.$http.get('/api/portal/clients', {
                params: this.loadParams
            });
            this.data = response.data;
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        async loadMore() {
            if (this.isLoading) return;
            if (!this.canLoadMore) return;
            this.isLoading = true;
            const response = await this.$http.get('/api/portal/clients', {
                params: {
                    ...this.loadParams,
                    skip: this.data.length
                }
            });
            this.data.push(...response.data);
            this.isLoading = false;
            this.canLoadMore = response.data.length > 0;
        },

        async editClient(client) {
            const result = await this.$modal(EditModal, { client });
            if (result === undefined) return;
            this.load();
        },

        sortByHeader(index, ascending) {
            this.sort = this.columnFields[index];
            this.sortAscending = ascending;
            this.load();
        },

        search() {
            this.filters.activeSearch = this.searchText;
        }
    }
};
</script>

<style lang="scss" scoped>
.filters > * {
    @apply ml-1;
}

.company-name {
    @apply text-gray-500 text-xs;
}

.map {
    @apply flex-1;
}
</style>
