<template>
    <div id="membership">
        <div class="w-full field-row justify-between flex-col">
            <div class="border border-gray-300 rounded-md px-4 py-5 sm:p-0 w-full">
                <div v-if="!membership" class="flex-1 p-3 flex items-center">
                    <div>This client has no membership.</div>
                    <button type="button" class="white ml-auto" @click="updateMembership">Enroll</button>
                </div>
                <dl v-else class="sm:divide-y sm:divide-gray-200">
                    <div class="p-2 grid grid-cols-3">
                        <dt class="text-sm font-medium text-gray-500 flex items-center">Plan</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            {{ membership.plan }}
                            <button type="button" class="white ml-2" @click="updateMembership">Edit</button>
                        </dd>
                    </div>
                    <div class="p-2 grid grid-cols-3">
                        <dt class="text-sm font-medium text-gray-500 flex items-center">Price</dt>
                        <dd class="mt-1 text-sm text-gray-900">
                            {{ $filter(membership.price, 'usCurrency')
                            }}{{ membership.duration !== 'indefinite' ? `/${membership.duration}` : null }}
                        </dd>
                    </div>
                    <template v-if="membership.renewalDate">
                        <div class="p-2 grid grid-cols-3">
                            <dt class="text-sm font-medium text-gray-500 flex items-center">Renewal Date</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                {{ $filter(membership.renewalDate, 'date') }}
                            </dd>
                        </div>
                        <div class="p-2 grid grid-cols-3">
                            <dt class="text-sm font-medium text-gray-500 flex items-center">Auto Renew</dt>
                            <dd class="mt-1 text-sm text-gray-900">
                                <SwitchGroup as="div" class="flex items-center flex-1">
                                    <Switch
                                        v-model="membership.autoRenew"
                                        :class="[
                                            membership.autoRenew ? 'bg-blue-600' : 'bg-gray-200',
                                            'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'
                                        ]"
                                    >
                                        <span class="sr-only">Auto-renew</span>
                                        <span
                                            aria-hidden="true"
                                            :class="[
                                                membership.autoRenew ? 'translate-x-5' : 'translate-x-0',
                                                'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
                                            ]"
                                        />
                                    </Switch>
                                </SwitchGroup>
                            </dd>
                        </div>
                    </template>
                </dl>
            </div>
        </div>
    </div>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';

import EditMembershipModal from './f-membership/m-edit-membership';

export default {
    props: ['client'],
    components: { SwitchGroup, Switch },

    data() {
        return {};
    },

    computed: {
        membership() {
            return this.client.membership
                ? {
                      plan: this.client.membership.plan.title,
                      price: this.client.membership.price,
                      duration: this.client.membership.duration,
                      renewalDate: this.client.membership.renewalDate
                          ? new Date(this.client.membership.renewalDate)
                          : null,
                      autoRenew: this.client.membership.isAutoRenewEnabled
                  }
                : null;
        }
    },

    methods: {
        async updateMembership() {
            const result = await this.$modal(EditMembershipModal, { client: this.client });
            if (result) Object.assign(this.client, result);
        }
    }
};
</script>

<style lang="scss" scoped>
#membership {
    @apply flex-1 flex;
}

.field {
    @apply flex flex-col flex-1 mb-2;

    label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }
}
.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}

.field-row {
    @apply flex mb-2;

    .field {
        @apply mb-0;

        &:not(:first-of-type) {
            @apply ml-2;
        }
    }
}
</style>
