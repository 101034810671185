import { createRouter, createWebHashHistory } from 'vue-router';

import EmbedGiftCards from '@/views/embed/gift-cards';
import EmbedPlans from '@/views/embed/plans';
import EmbedSignup from '@/views/embed/signup';
import Agencies from '@/views/portal/agencies';
import Clients from '@/views/portal/clients';
import MembershipPlans from '@/views/portal/membership-plans';
import Officers from '@/views/portal/officers';
import Prescheduled from '@/views/portal/prescheduled';
import PromoCodes from '@/views/portal/promo-codes';
import Requests from '@/views/portal/requests/requests';
import ResetPassword from '@/views/portal/reset-password';
import Zones from '@/views/portal/zones';

const routes = [
    {
        path: '/',
        redirect: '/requests'
    },
    {
        path: '/reset-password/:token/:role?',
        name: 'ResetPassword',
        component: ResetPassword
    },
    {
        path: '/agencies/:id?',
        component: Agencies
    },
    {
        path: '/officers/:id?',
        component: Officers
    },
    {
        path: '/clients/:id?',
        component: Clients
    },
    {
        path: '/requests',
        component: Requests
    },
    {
        path: '/prescheduled',
        component: Prescheduled
    },
    {
        path: '/zones',
        component: Zones
    },
    {
        path: '/membership-plans',
        component: MembershipPlans
    },
    {
        path: '/promo-codes',
        component: PromoCodes
    },
    {
        path: '/embed/plans',
        name: 'PlansEmbed',
        component: EmbedPlans
    },
    {
        path: '/embed/signup',
        name: 'SignupEmbed',
        component: EmbedSignup
    },
    {
        path: '/embed/gift-cards',
        name: 'GiftCardsEmbed',
        component: EmbedGiftCards
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes
});

export default router;
