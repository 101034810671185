<template>
    <modal ref="modal" maxWidth="xl" :buttons="['close']">
        <template v-slot:content>
            <div class="text-sm">
                <div v-for="(q, i) in qs" :key="String(i)" class="pb-5">
                    <div class="font-bold">{{ q.label }}</div>
                    <div v-user-text="q.value"></div>
                </div>
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';

export default {
    components: { Modal },

    data() {
        return {
            qs: null
        };
    }
};
</script>
