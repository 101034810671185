<template>
    <modal ref="modal" fullWidth maxWidth="2xl" :buttons="['cancel', 'save']" @saved="handleSave">
        <template v-slot:title>
            {{ agency.id ? 'Manage Agency' : 'Create Agency' }}
        </template>
        <template v-slot:content>
            <tabs
                v-if="agency.id"
                :tabs="['Profile', 'Officers', 'Requests']"
                :activeTab="activeTab"
                @tab-selected="tab => (activeTab = tab)"
            />
            <div class="flex flex-col mt-4">
                <template v-if="activeTab == 'Profile'">
                    <div class="flex-1 w-full mb-4">
                        <label for="agency-name"> Agency Name </label>
                        <div class="mt-1">
                            <input
                                v-model="agency.name"
                                type="text"
                                id="agency-name"
                                class="text-input"
                                placeholder="ACME, Inc."
                                autocomplete="off"
                            />
                        </div>
                    </div>

                    <template v-if="!agency.id">
                        <div class="flex w-full mb-4">
                            <div class="mr-4 flex-1 w-full">
                                <label for="contact-fname"> Contact Name </label>
                                <div class="mt-1">
                                    <input
                                        v-model="pocInfo.firstName"
                                        type="text"
                                        id="contact-fname"
                                        class="text-input"
                                        placeholder="John"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                            <div class="flex-1 w-full">
                                <label for="contact-lname">&nbsp;</label>
                                <div class="mt-1">
                                    <input
                                        v-model="pocInfo.lastName"
                                        type="text"
                                        id="contact-lname"
                                        class="text-input"
                                        placeholder="Doe"
                                        autocomplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="mb-4">
                            <label for="email"> Contact Email </label>
                            <div class="mt-1">
                                <input
                                    v-model="pocInfo.email"
                                    type="email"
                                    id="email"
                                    class="text-input"
                                    placeholder="johndoe@example.com"
                                    autocomplete="off"
                                />
                            </div>
                        </div>
                    </template>

                    <!-- <template v-else>
                        <div class="flex w-full mb-4">
                            <div class="mb-4">
                                <label for="email"> Point of Contact </label>
                                <div class="mt-1">
                                    <smart-select v-model="pocInfo" />
                                </div>
                            </div>
                        </div>
                    </template> -->
                </template>

                <agency-officers v-else-if="activeTab == 'Officers'" :agency="agency" @bye="$dismiss()" />
                <agency-requests v-else-if="activeTab == 'Requests'" :agency="agency" />
            </div>
        </template>
    </modal>
</template>

<script>
import Modal from '@/common/components/c-modal';
import Tabs from '@/common/components/c-tabs';

import AgencyOfficers from './m-edit-agency/f-officers';
import AgencyRequests from './m-edit-agency/f-requests';

export default {
    components: {
        Modal,
        Tabs,
        AgencyOfficers,
        AgencyRequests
    },

    data() {
        return {
            agency: null,
            pocId: null,
            pocInfo: {
                id: null,
                firstName: null,
                lastName: null,
                email: null
            },
            activeTab: 'Profile'
        };
    },

    created() {
        if (!this.agency) {
            this.agency = {
                name: ''
            };
        }
    },

    methods: {
        async handleSave() {
            this.$refs.modal.mask();

            try {
                let data = {
                    name: this.agency.name
                };

                if (!data.name) this.$throwUserError('Please enter an agency name.');

                if (!this.agency.id) {
                    if (!this.pocInfo.firstName) this.$throwUserError('Please enter a point of contact first name.');
                    if (!this.pocInfo.lastName) this.$throwUserError('Please enter a point of contact last name.');
                    if (!this.pocInfo.email) this.$throwUserError('Please enter a point of contact email.');
                    data.pocUser = this.pocInfo;
                }

                const response = await this.$http.postOrPut('/api/portal/agencies', this.agency.id, data);
                this.$dismiss(response.data);
            } catch (err) {
                this.$refs.modal.unmask();
                this.$alert('Save', err);
            }
        }
    }
};
</script>

<style lang="scss" scoped></style>
