<template>
    <div id="memberships">
        <div class="top-filters">
            <h1>Membership Plans</h1>

            <div class="filters">
                <button type="button" class="blue" @click="editPlan()">
                    <i class="far fa-plus text-lg" />
                </button>
            </div>
        </div>
        <table-component
            :columns="['Name', 'Type', `Price`, 'Submembers', 'Position']"
            :data="data"
            :isLoading="data === null"
            clickable
            hoverable
        >
            <template v-slot:rows>
                <tr v-for="(plan, i) in data" :key="i" @click="editPlan(plan)">
                    <td>{{ plan.title }}</td>
                    <td>{{ plan.type.ucfirst() }}</td>
                    <td>
                        <template v-if="plan.duration === 'indefinite'">${{ plan.priceIndefinite }} one-time </template>
                        <template v-else>
                            {{
                                [
                                    plan.priceMonthly && `$${plan.priceMonthly} monthly`,
                                    plan.priceYearly && `$${plan.priceYearly} annually`
                                ]
                                    .filter(v => v)
                                    .join('; ')
                            }}
                        </template>
                    </td>
                    <td>
                        {{ plan.includedSubmemberCount || 'None' }} included.
                        <template v-if="plan.maximumSubmemberCount !== 0">
                            ${{ plan.pricePerSubmember }} per additional.
                            {{ plan.maximumSubmemberCount || 'No' }} maximum.
                        </template>
                        <template v-else>No additional allowed.</template>
                    </td>
                    <td>{{ plan.position ?? '-' }}</td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import TableComponent from '@/common/components/c-table';

import EditModal from './membership-plans/m-edit-plan';

export default {
    components: {
        TableComponent
    },

    data() {
        return {
            data: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.data = null;
            const response = await this.$http.get('/api/portal/plans');
            this.data = response.data;
        },

        async editPlan(plan) {
            const result = await this.$modal(EditModal, { plan });
            if (result === undefined) return;
            this.load();
        }
    }
};
</script>
