<template>
    <div class="loader">
        <div class="inner">
            <div class="dot yellow"></div>
            <div class="dot indigo"></div>
            <div class="dot teal"></div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.loader {
    @apply flex items-center p-12;

    &:not(.no-center) {
        @apply justify-center;
    }

    .inner {
        @apply relative flex items-center justify-center w-[60px] h-[60px];
        animation: spin 3s linear infinite;
        .dot {
            @apply absolute h-6 w-6 rounded-full;

            &.yellow {
                @apply bg-[#FCFD54] top-1;
                animation: move 1s alternate infinite;
            }

            &.indigo {
                @apply bg-[#4361EE] left-1 bottom-1;
                animation: move2 1s alternate infinite;
            }

            &.teal {
                @apply bg-[#15ABB4] right-1 bottom-1;
                animation: move3 1s alternate infinite;
            }
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes move {
        0% {
            transform: translateY(0);
        }
        100% {
            transform: translateY(-4px);
        }
    }

    @keyframes move2 {
        0% {
            transform: translateY(0) translateX(0);
        }
        100% {
            transform: translateY(4px) translateX(-4px);
        }
    }

    @keyframes move3 {
        0% {
            transform: translateY(0) translateX(0);
        }
        100% {
            transform: translateY(4px) translateX(4px);
        }
    }
}
</style>
