<template>
    <div class="flex sm:flex-row flex-col-reverse sm:space-x-3 flex-1 min-h-0 overflow-auto">
        <div class="left flex-1 flex flex-col justify-between">
            <div v-if="isSysAdmin" class="mb-4">
                <label for="agency" class="block text-sm font-medium text-gray-700"> Agency </label>
                <div class="mt-1">
                    <smart-select v-model="profile.agency" url="/api/portal/agencies" placeholder="Select an agency" />
                </div>
            </div>

            <div class="mb-4">
                <label for="officer-name" class="block text-sm font-medium text-gray-700"> Name </label>
                <div class="mt-1 flex space-x-2">
                    <input
                        v-model="profile.firstName"
                        type="text"
                        id="officer-name"
                        class="text-input"
                        autocomplete="off"
                        placeholder="John"
                        v-autofocus
                    />
                    <input
                        v-model="profile.lastName"
                        type="text"
                        class="text-input"
                        autocomplete="off"
                        placeholder="Doe"
                    />
                </div>
            </div>

            <div class="mb-4">
                <label for="officer-name" class="block text-sm font-medium text-gray-700"> Email </label>
                <div class="mt-1">
                    <input
                        v-model="profile.email"
                        type="email"
                        id="officer-email"
                        class="text-input"
                        autocomplete="off"
                        placeholder="johndoe@example.com"
                    />
                </div>
            </div>

            <div class="mb-4">
                <label for="officer-name" class="block text-sm font-medium text-gray-700"> Cell Phone </label>
                <div class="mt-1">
                    <input
                        v-model="profile.phoneNumber"
                        type="tel"
                        id="officer-phone"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
            </div>

            <div class="mb-4">
                <label for="officer-license" class="block text-sm font-medium text-gray-700"> License Number </label>
                <div class="mt-1">
                    <input
                        v-model="profile.licenseNumber"
                        type="text"
                        id="officer-license"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
            </div>

            <div class="mb-4 flex space-x-2">
                <div class="flex-1">
                    <label for="active" class="block text-sm font-medium text-gray-700"> Active </label>
                    <div class="mt-1">
                        <SwitchGroup as="div" class="switch-group">
                            <Switch v-model="profile.isActive" :class="[profile.isActive && 'on', 'switch']">
                                <span class="sr-only">Enabled</span>
                                <span aria-hidden="true" :class="[profile.isActive && 'on', 'thumb']" />
                            </Switch>
                        </SwitchGroup>
                    </div>
                </div>

                <div class="flex-1">
                    <label for="active" class="block text-sm font-medium text-gray-700"> Plain Clothes </label>
                    <div class="mt-1">
                        <SwitchGroup as="div" class="switch-group">
                            <Switch
                                v-model="profile.officerPlainclothesEnabled"
                                :class="[profile.officerPlainclothesEnabled && 'on', 'switch']"
                            >
                                <span class="sr-only">Enabled</span>
                                <span
                                    aria-hidden="true"
                                    :class="[profile.officerPlainclothesEnabled && 'on', 'thumb']"
                                />
                            </Switch>
                        </SwitchGroup>
                    </div>
                </div>

                <div class="flex-1">
                    <label for="active" class="block text-sm font-medium text-gray-700"> Manager </label>
                    <div class="mt-1">
                        <SwitchGroup as="div" class="switch-group">
                            <Switch v-model="isManager" :class="[isManager && 'on', 'switch']">
                                <span class="sr-only">Enabled</span>
                                <span aria-hidden="true" :class="[isManager && 'on', 'thumb']" />
                            </Switch>
                        </SwitchGroup>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="officer.id" class="right pr-1">
            <div class="mb-4">
                <label class="block text-sm font-medium text-gray-700"> Photo </label>
                <Avatar class="avatar" :person="profile" />
                <div v-if="profile.rating != 0" class="flex flex-row items-center mt-3 px-2">
                    <star-ratings
                        :starColor="'#FFD700'"
                        :inactiveColor="'#9CA3AF'"
                        :step="0.01"
                        disableClick
                        starSize="20"
                        :showControl="false"
                        v-model="profile.rating"
                    />
                    <span class="ml-2 text-sm pt-1.5">{{ profile.rating }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';
import StarRatings from 'vue3-star-ratings';

import Avatar from '@/common/components/c-avatar';

export default {
    store: ['session'],
    props: ['officer'],

    components: { StarRatings, Avatar, Switch, SwitchGroup },

    data() {
        return {
            profile: this.officer,
            isManager: this.officer.accessLevel !== 'standard'
        };
    },

    computed: {
        isSysAdmin() {
            return this.session.accessLevel === 'sysadmin';
        }
    },

    watch: {
        isManager() {
            this.profile.accessLevel = this.isManager ? 'manager' : 'standard';
        },

        profile: {
            deep: true,
            handler() {
                this.$emit('update:officer', this.profile);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar {
    @apply w-44 h-44 rounded-lg;

    :deep(i) {
        @apply text-6xl;
    }
}

:deep(.vue3-star-ratings__wrapper) {
    @apply p-0 m-0;
}
:deep(.stars) {
    @apply m-0;
}
</style>
