import { Loader } from '@googlemaps/js-api-loader';

export class MapHelpers {
    static loadingPromise = null;
    static gmaps = null;

    static async init() {
        if (this.gmaps) {
            return this.gmaps;
        }

        if (this.loadingPromise) {
            return this.loadingPromise;
        }

        // eslint-disable-next-line no-async-promise-executor
        this.loadingPromise = new Promise(async resolve => {
            const loader = new Loader({
                apiKey: process.env.VUE_APP_GOOGLE_API_KEY,
                libraries: ['drawing', 'visualization']
            });

            const google = await loader.load();
            this.gmaps = google.maps;

            resolve(this.gmaps);
        });

        return this.loadingPromise;
    }
}
