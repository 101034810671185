<template>
    <div v-if="showTabs">
        <div class="sm:hidden">
            <label for="tabs" class="sr-only">Select a tab</label>
            <select
                id="tabs"
                class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md"
            >
                <option v-for="tab in compactedTabs" :key="tab" :selected="activeTab == tab">
                    {{ tab }}
                </option>
            </select>
        </div>
        <div class="hidden sm:block">
            <div class="border-b border-gray-200">
                <nav class="-mb-px flex space-x-8" aria-label="Tabs">
                    <a
                        v-for="tab in tabs"
                        :key="tab"
                        href="#"
                        @click.prevent="$emit('tab-selected', tab)"
                        :class="[
                            activeTab == tab
                                ? 'border-blue-500 text-blue-600'
                                : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                            'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                        ]"
                        :aria-current="tab.current ? 'page' : undefined"
                    >
                        {{ tab }}
                    </a>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['tabs', 'activeTab'],

    computed: {
        compactedTabs() {
            return this.tabs.filter(t => !!t);
        },

        showTabs() {
            return this.compactedTabs.length > 1;
        }
    },

    mounted() {
        if (!this.activeTab) this.$emit('tab-selected', this.tabs[0]);
    }
};
</script>
