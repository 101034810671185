<template>
    <div id="signup">
        <form ref="signupInner" @submit.prevent="submit">
            <div v-if="isSubmitted">
                Welcome to Secur, <strong>{{ user.firstName }}</strong
                >. Your membership is now active. If you don't already have the app, check your email for a link to
                download it.
            </div>
            <template v-else>
                <div v-if="loadError" class="error load-error">{{ loadError }}</div>

                <div v-if="!shouldDisplayPromoCode" class="promo-code-link">
                    <a href="#" @click.prevent="shouldDisplayPromoCode = true">Have a promo code?</a>
                </div>
                <section v-else class="">
                    <h1>1. Promo Code</h1>
                    <div class="form-group">
                        <input v-model="promoCode" type="text" placeholder="promo code" v-autofocus />
                        <button v-disabled="isLoading" @click.prevent="load">Apply</button>
                    </div>
                </section>

                <section>
                    <h1>{{ promoCodeOffset + 1 }}. Choose Your Plan</h1>
                    <Loader v-if="isLoading" />
                    <div v-else class="form-group">
                        <label v-for="plan in plans" :key="plan.id" class="radio">
                            <input type="radio" v-model="selectedPlanId" :value="plan.id" />
                            <div>
                                <span class="label">{{ plan.title }}</span>
                                <span v-if="plan.duration === 'recurring'" class="description">
                                    <template v-if="plan.priceMonthly && plan.priceYearly">
                                        {{ $filter(plan.priceMonthly, 'usCurrency', 'shortMoney') }}/month &bull;
                                        {{ $filter(plan.priceYearly, 'usCurrency', 'shortMoney') }}/year
                                    </template>
                                    <template v-else-if="plan.priceMonthly">
                                        {{ $filter(plan.priceMonthly, 'usCurrency', 'shortMoney') }}/month
                                    </template>
                                    <template v-else>
                                        {{ $filter(plan.priceYearly, 'usCurrency', 'shortMoney') }}/year
                                    </template>
                                </span>
                                <span v-else class="description">
                                    {{ $filter(plan.priceIndefinite, 'usCurrency', 'shortMoney') }} one-time
                                </span>
                            </div>
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ promoCodeOffset + 2 }}. Choose Your Billing Cycle</h1>
                    <Loader v-if="isLoading" />
                    <div v-else-if="selectedPlan" class="form-group">
                        <template v-if="selectedPlan.duration === 'recurring'">
                            <template v-if="selectedPlan.priceYearly !== 0">
                                <label class="radio">
                                    <input v-model="selectedDuration" type="radio" name="cycle" value="year" />
                                    <div>
                                        <span class="label">Annual</span>
                                        <span class="description">
                                            {{ $filter(selectedPlan.priceYearly, 'usCurrency', 'shortMoney') }}/year
                                        </span>
                                    </div>
                                </label>
                            </template>
                            <template v-if="selectedPlan.priceMonthly !== 0">
                                <label class="radio">
                                    <input v-model="selectedDuration" type="radio" name="cycle" value="month" />
                                    <div>
                                        <span class="label">Monthly</span>
                                        <span class="description">
                                            {{ $filter(selectedPlan.priceMonthly, 'usCurrency', 'shortMoney') }}/month
                                        </span>
                                    </div>
                                </label>
                            </template>
                        </template>
                        <template v-else>
                            <label class="radio">
                                <input v-model="selectedDuration" type="radio" name="cycle" value="indefinite" />
                                <div>
                                    <span class="label">One-Time</span>
                                    <span class="description">
                                        {{ $filter(selectedPlan.priceIndefinite, 'usCurrency', 'shortMoney') }}
                                    </span>
                                </div>
                            </label>
                        </template>
                    </div>
                </section>

                <section>
                    <h1>{{ promoCodeOffset + 3 }}. Set Up Your Account</h1>

                    <div v-if="selectedPlan?.type === 'business'" class="form-group">
                        <label class="input">
                            <span class="label">Company</span>
                            <input v-model="user.company" type="text" required />
                        </label>
                    </div>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">First Name</span>
                            <input v-model="user.firstName" type="text" required autocomplete="given-name" />
                        </label>

                        <label class="input">
                            <span class="label">Last Name</span>
                            <input v-model="user.lastName" type="text" required autocomplete="family-name" />
                        </label>
                    </div>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">Email</span>
                            <input v-model="user.email" type="email" required autocomplete="email" />
                        </label>

                        <label class="input">
                            <span class="label">Zip Code</span>
                            <input v-model="user.zipCode" type="text" required autocomplete="postal-code" />
                        </label>
                    </div>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">Mobile Phone</span>
                            <input v-model="user.phoneNumber" type="tel" required />
                        </label>
                    </div>

                    <div class="form-group">
                        <div class="input checkbox">
                            <input v-model="user.marketingOptIn" type="checkbox" />
                            <span>Please send me tips on getting the most out of my Secur membership.</span>
                        </div>
                    </div>
                </section>

                <section v-show="isPaid">
                    <h1>{{ promoCodeOffset + 4 }}. Set Up Payment</h1>
                    <div class="form-group">
                        <label class="input">
                            <div class="stripe-input">
                                <div ref="cardElement" />
                            </div>
                        </label>
                    </div>
                </section>

                <div v-if="error" class="error">{{ error }}</div>

                <Loader v-if="isSubmitting" class="no-center"></Loader>
                <section v-else class="submit">
                    <button type="submit" v-disabled="isLoading">Submit</button>
                </section>
            </template>
        </form>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';

import Loader from '../../common/components/c-loader';

export default {
    components: { Loader },

    data() {
        return {
            shouldDisplayPromoCode: false,
            promoCode: null,
            isLoading: false,
            loadError: null,
            selectedPlanId: null,
            selectedDuration: 'year',
            user: {
                company: null,
                firstName: null,
                lastName: null,
                email: null,
                zipCode: null,
                marketingOptIn: true
            },
            plans: [],
            error: null,
            isSubmitting: false,
            isSubmitted: false
        };
    },

    computed: {
        promoCodeOffset() {
            return this.shouldDisplayPromoCode ? 1 : 0;
        },

        selectedPlan() {
            return this.plans.find(plan => plan.id === this.selectedPlanId);
        },

        selectedPrice() {
            return this.selectedPlan?.[
                this.selectedDuration === 'indefinite'
                    ? 'priceIndefinite'
                    : this.selectedDuration === 'year'
                    ? 'priceYearly'
                    : 'priceMonthly'
            ];
        },

        isPaid() {
            return this.selectedPrice > 0;
        }
    },

    watch: {
        selectedPlan() {
            if (this.selectedPlan.duration === 'indefinite') {
                this.selectedDuration = 'indefinite';
            } else if (this.selectedDuration === 'indefinite') {
                this.selectedDuration = this.selectedPlan.priceYearly ? 'year' : 'month';
            } else if (this.selectedDuration === 'year' && !this.selectedPlan.priceYearly) {
                this.selectedDuration = 'month';
            } else if (this.selectedDuration === 'month' && !this.selectedPlan.priceMonthly) {
                this.selectedDuration = 'year';
            }
        }
    },

    async mounted() {
        await this.setupStripe();
        await this.load();
        this.preselectPlan();

        this.ready = true;
        window.addEventListener('resize', this.resizeHandler);
        this.resizeHandler();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        resizeHandler() {
            window.parent.postMessage(`contentHeight:${this.$refs.signupInner.offsetHeight}`, '*');
        },

        async load() {
            try {
                this.isLoading = true;
                this.loadError = null;

                const response = await this.$http.get('/api/embed/plans', {
                    params: {
                        promoCode: this.promoCode
                    }
                });
                this.plans = response.data;
            } catch (err) {
                if (err.code === 'USERERR') {
                    this.loadError = err.message;
                } else {
                    throw err;
                }
            } finally {
                this.isLoading = false;
            }
        },

        setupStripe() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
                const elements = stripe.elements({
                    fonts: [
                        {
                            cssSrc: 'https://fonts.googleapis.com/css?family=Inter'
                        }
                    ]
                });
                const cardElement = elements.create('card', {
                    style: {
                        base: {
                            fontFamily: 'Inter'
                        }
                    }
                });
                cardElement.mount(this.$refs.cardElement);
                cardElement.once('ready', resolve);

                this._stripe = stripe;
                this._cardElement = cardElement;
            });
        },

        preselectPlan() {
            if (this.$route.query.planId) {
                this.selectedPlanId = parseInt(this.$route.query.planId);
            } else if (this.$route.query.planType) {
                this.selectedPlanId = this.plans.find(plan => plan.type === this.$route.query.planType)?.id;
            }
            if (!this.selectedPlanId || !this.plans.find(plan => plan.id === this.selectedPlanId)) {
                this.selectedPlanId = this.plans[0].id;
            }
        },

        async submit() {
            if (this.isSubmitting) return;
            this.isSubmitting = true;
            this.error = null;

            try {
                if (this.user.zipCode.length < 5 || /[^\d]/.test(this.user.zipCode))
                    this.$throwUserError('Please enter a valid 5-digit ZIP code.');

                const stripePaymentMethodId = this.isPaid ? await this.getStripePaymentMethodId() : undefined;

                const response = await this.$http.post('/api/embed/signup', {
                    stripePaymentMethodId,
                    promoCode: this.promoCode,
                    planId: this.selectedPlanId,
                    duration: this.selectedDuration,
                    company: this.user.company,
                    firstName: this.user.firstName,
                    lastName: this.user.lastName,
                    email: this.user.email,
                    zip: this.user.zipCode,
                    phoneNumber: this.user.phoneNumber,
                    marketingOptIn: this.user.marketingOptIn
                });

                if (!response.data.ok) {
                    throw new Error('unexpected response');
                }

                this.isSubmitted = true;
                setTimeout(() => this.resizeHandler(), 250);

                window.parent.postMessage(`signupComplete`, '*');
            } catch (err) {
                if (err.code === 'USERERR') {
                    this.error = err.message;
                } else {
                    this.$reportError(err);
                    this.error = `Uh oh! An application error was encountered while processing your request. Please try again. If the problem persists, please contact info@gosecur.com. -- ${String(
                        err
                    )}`;
                }
            }

            this.isSubmitting = false;
        },

        async getStripePaymentMethodId() {
            const stripeResult = await this._stripe.createPaymentMethod({
                type: 'card',
                card: this._cardElement
            });
            if (stripeResult.error) {
                this.$throwUserError(stripeResult.error.message);
            }
            return stripeResult.paymentMethod.id;
        }
    }
};
</script>

<style lang="scss">
#signup {
    @apply flex-1;

    form {
        @apply bg-white px-4 sm:px-12 md:px-24 py-12;

        .promo-code-link {
            @apply text-center md:text-right mb-6;

            a {
                @apply p-2 text-[#4361EE] hover:opacity-75 font-bold;
            }
        }

        section {
            &:not(:first-of-type) {
                @apply mt-12;
            }

            &:last-child {
                @apply mb-12;
            }

            h1 {
                @apply text-2xl font-semibold;
            }

            .form-group {
                @apply flex-1 flex flex-wrap gap-3 ml-8 pt-4;

                .radio {
                    @apply flex-1 h-32 min-w-[220px] max-w-[280px];

                    div {
                        @apply flex-1 h-full flex flex-col items-center justify-center p-6 bg-neutral-200 duration-150 ring-0 ring-[#4361EE] cursor-pointer ease-in-out;

                        .label {
                            @apply text-xl font-bold;
                        }

                        .description {
                            @apply text-xs;
                        }

                        .detail {
                            @apply text-xs mt-2;
                        }
                    }

                    input[type='radio'] {
                        @apply appearance-none hidden;
                    }

                    input[type='radio']:checked + div {
                        @apply bg-white ring-[3px] ring-[#4361EE];
                    }
                }

                .input {
                    @apply flex-1 flex flex-col max-w-md;

                    .label {
                        @apply text-xs;
                    }

                    input,
                    .stripe-input {
                        @apply border-none bg-neutral-200 mt-2 duration-75 ease-in-out;

                        // unfortunate hack for removing autofill styling
                        &:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0 30px rgb(229, 229, 229) inset, 0 0 0 0px rgb(0, 0, 0);

                            &:focus {
                                -webkit-box-shadow: 0 0 0 30px rgb(229, 229, 229) inset, 0 0 0 2px rgb(255, 255, 255),
                                    0 0 0 4px rgb(0, 0, 0);
                            }
                        }

                        &:focus {
                            @apply border-transparent ring-2 ring-black ring-offset-2;
                        }
                    }

                    .stripe-input {
                        @apply p-3;
                    }

                    &.checkbox {
                        @apply flex-row max-w-none items-center mt-2;

                        input {
                            @apply text-black mt-0;

                            &:checked {
                                @apply bg-black;
                            }
                        }

                        span {
                            @apply ml-2 text-xs;
                        }
                    }
                }
            }

            .stripe-form {
                // max-w is semi-arbitrary value based on input max-w + margin. so payment fields are same width as inputs.
                @apply ml-8 mt-4 max-w-[908px];
            }
        }

        .error {
            @apply ml-8 text-red-700;

            &.load-error {
                @apply mb-6;
            }

            &:not(.load-error) {
                @apply mt-6;
            }
        }

        section.submit {
            @apply ml-8;
        }

        button {
            @apply relative text-black bg-white font-bold text-lg py-1 px-20 border-[3px] border-black overflow-hidden z-10 cursor-pointer duration-75 ease-in-out;

            &:hover {
                @apply text-white;
            }

            &:after {
                @apply absolute w-full h-full bg-black left-0 top-full duration-75 pointer-events-none -z-10 ease-in-out;
                content: '';
            }

            &:hover:after {
                @apply top-0;
            }
        }
    }
}
</style>
