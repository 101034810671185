<template>
    <div class="left flex-1 flex flex-col justify-between">
        <div v-if="isSysAdmin" class="field-row">
            <div class="field">
                <label>Client Type</label>
                <smart-select
                    v-autofocus="!client.id"
                    v-model="profile.clientType"
                    :options="['individual', 'business', 'submember']"
                    :titleFormatter="t => t.ucfirst()"
                />
            </div>

            <div class="field">
                <template v-if="profile.clientType === 'submember'">
                    <label>Membership Owner</label>
                    <div class="flex">
                        <smart-select
                            v-model="profile.masterId"
                            placeholder="Select an owner..."
                            url="/api/portal/clients"
                            :url-params="{
                                excludeSubmembers: true
                            }"
                            valueKey="id"
                            preload
                            remote-search
                            :title-formatter="formatClientListItem"
                            class="flex-1"
                        />
                        <button
                            v-if="client.id"
                            class="white ml-1"
                            v-disabled="!profile.masterId"
                            @click="openMasterUser"
                        >
                            <i class="fas fa-external-link"></i>
                        </button>
                    </div>
                </template>
            </div>
        </div>

        <div v-if="profile.clientType === 'business'" class="field-row">
            <div class="field">
                <label for="client-company">Company</label>
                <input
                    v-model="profile.company"
                    type="text"
                    id="client-company"
                    class="text-input"
                    autocomplete="off"
                />
            </div>
        </div>

        <template v-if="!isStoredCompany">
            <div class="field-row">
                <div class="field">
                    <label for="client-first-name">First Name</label>
                    <input
                        v-model="profile.firstName"
                        type="text"
                        id="client-first-name"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
                <div class="field">
                    <label for="client-last-name">Last Name</label>
                    <input
                        v-model="profile.lastName"
                        type="text"
                        id="client-last-name"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
            </div>

            <div class="field-row">
                <div class="field">
                    <label for="client-email">Email</label>
                    <input
                        v-model="profile.email"
                        type="email"
                        id="client-email"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
                <div class="field">
                    <label for="client-phone">Cell Phone</label>
                    <input
                        v-model="profile.phoneNumber"
                        type="tel"
                        id="client-phone"
                        class="text-input"
                        autocomplete="off"
                    />
                </div>
            </div>

            <div class="field-row">
                <div class="field">
                    <label for="client-zip">ZIP Code</label>
                    <input
                        v-model="profile.zip"
                        type="text"
                        id="client-zip"
                        class="text-input"
                        autocomplete="off"
                        maxlength="5"
                        @input="formatZip"
                    />
                </div>
                <div class="field"></div>
            </div>

            <div class="field-row">
                <div class="field">
                    <label>Enabled</label>
                    <SwitchGroup as="div" class="switch-group">
                        <Switch v-model="profile.isActive" :class="[profile.isActive && 'on', 'switch']">
                            <span class="sr-only">Enabled</span>
                            <span aria-hidden="true" :class="[profile.isActive && 'on', 'thumb']" />
                        </Switch>
                    </SwitchGroup>
                </div>

                <div class="field">
                    <label>Manager</label>
                    <SwitchGroup as="div" class="switch-group">
                        <Switch v-model="isManager" :class="[isManager && 'on', 'switch']">
                            <span class="sr-only">Manager</span>
                            <span aria-hidden="true" :class="[isManager && 'on', 'thumb']" />
                        </Switch>
                    </SwitchGroup>
                </div>
            </div>
        </template>
    </div>

    <div v-if="client.id && !isStoredCompany" class="right w-1/3">
        <label class="block text-sm font-medium text-gray-700"> Photo </label>
        <Avatar :person="client" />
        <div class="flex flex-row justify-between w-44 mt-3 px-2">
            <star-ratings
                :starColor="'#FFD700'"
                :inactiveColor="'#9CA3AF'"
                :step="0.01"
                disableClick
                starSize="20"
                :showControl="false"
                v-model="profile.rating"
            />
            <span class="text-sm pt-1.5">{{ profile.rating }}</span>
        </div>
    </div>
</template>

<script>
import { Switch, SwitchGroup } from '@headlessui/vue';
import _ from 'lodash';
import StarRatings from 'vue3-star-ratings';

import Avatar from '@/common/components/c-avatar';

export default {
    emits: ['openClient'],
    store: ['session'],
    props: ['client'],

    components: { StarRatings, Avatar, Switch, SwitchGroup },

    data() {
        return {
            profile: this.client,
            isManager: this.client.accessLevel !== 'standard'
        };
    },

    computed: {
        isSysAdmin() {
            return this.session.accessLevel === 'sysadmin';
        },

        isStoredCompany() {
            return this.profile.clientType === 'business' && this.profile.id;
        }
    },

    watch: {
        isManager() {
            this.profile.accessLevel = this.isManager ? 'manager' : 'standard';
        }
    },

    methods: {
        formatClientListItem(item) {
            if (item.clientType === 'business') return item.company;
            return `${item.firstName} ${item.lastName}`;
        },

        openMasterUser() {
            this.$emit('openClient', { id: this.profile.masterId });
        },

        formatZip() {
            this.profile.zip = this.profile.zip.replace(/\D/g, '');
        },

        async save() {
            if (!this.profile.clientType) this.$throwUserError('Please select a client type.');
            if (this.profile.clientType === 'business' && !this.profile.company)
                this.$throwUserError('Please enter a company name.');
            if (this.profile.clientType === 'submember' && !this.profile.masterId)
                this.$throwUserError('Please select a membership owner.');
            if (!this.isStoredCompany) {
                if (!this.profile.firstName) this.$throwUserError('Please enter a first name.');
                if (!this.profile.lastName) this.$throwUserError('Please enter a last name.');
                if (!this.profile.email) this.$throwUserError('Please enter an email address.');
            }
            const profileData = _.pick(this.profile, [
                'clientType',
                'masterId',
                'company',
                'firstName',
                'lastName',
                'email',
                'phone',
                'zip',
                'isActive',
                'accessLevel'
            ]);
            const response = await this.$http.postOrPut('/api/portal/clients', this.client.id, profileData);
            return response.data;
        }
    }
};
</script>

<style lang="scss" scoped>
.field {
    @apply flex flex-col flex-1 mb-2;

    label {
        @apply block text-sm font-medium text-gray-700 mb-1;
    }
}

.input {
    @apply shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md;
}

.field-row {
    @apply flex mb-2;

    .field {
        @apply mb-0;

        &:not(:first-of-type) {
            @apply ml-2;
        }

        &.parent-membership {
            flex-grow: 0 !important;
            flex-shrink: 1 !important;
        }
    }
}

.avatar {
    @apply w-44 h-44 rounded-lg;

    :deep(i) {
        @apply text-6xl;
    }
}

.vue3-star-ratings__wrapper {
    @apply p-0 m-0;
}
.stars {
    @apply m-0;
}
</style>
