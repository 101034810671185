<template>
    <div id="requests">
        <div class="top-filters">
            <h1>
                {{ $route.name }}
            </h1>
        </div>

        <PrescheduledTable emptyText="There are no scheduled requests." />
    </div>
</template>

<script>
import PrescheduledTable from './prescheduled-table';

export default {
    components: { PrescheduledTable }
};
</script>

<style lang="scss" scoped></style>
