<template>
    <DetailPopover :title="`Request #${requestId}`" @close="close">
        <div v-if="!request" class="flex-1 flex items-center justify-center">
            <i class="fad fa-spinner-third fa-spin text-5xl" />
        </div>

        <template v-else>
            <Map
                id="map"
                :center="{
                    lat: request.requestCoordinate.y,
                    lng: request.requestCoordinate.x
                }"
                :zoom="15"
                :marker="{
                    lat: request.requestCoordinate.y,
                    lng: request.requestCoordinate.x
                }"
                :options="{ streetViewControl: false, controlSize: 24 }"
            ></Map>

            <div class="py-3">
                <tabs
                    :tabs="['Overview', 'Event Log']"
                    :activeTab="activeTab"
                    @tab-selected="tab => (activeTab = tab)"
                    class="px-4 sm:px-4"
                />
            </div>

            <div v-if="activeTab == 'Overview'" class="overview">
                <div class="row">
                    <div class="detail-item">
                        <label>Status</label>
                        <span>{{ request.status.ucfirst() }}</span>
                    </div>

                    <div class="detail-item">
                        <label>Requested At</label>
                        <span>{{ $filter(request.requestAt, 'dateTime') }}</span>
                    </div>
                </div>

                <div class="row">
                    <div class="detail-item">
                        <label>Client</label>
                        <a href="#">
                            {{ request.client.firstName }}
                            {{ request.client.lastName }}
                        </a>
                    </div>
                    <div class="detail-item">
                        <label>Officer</label>
                        <a v-if="request.officer" href="#">
                            {{ request.officer.firstName }}
                            {{ request.officer.lastName }}
                        </a>
                        <span v-else>-</span>
                    </div>
                </div>

                <div class="row">
                    <!-- <div class="detail-item">
                        <label>Start Time</label>
                        <span>{{ $filter(request.startTime, 'time') }}</span>
                    </div>
                    <div class="detail-item">
                        <label>End Time</label>
                        <span>
                            {{ $filter(request.endTime, 'time') }}
                        </span>
                    </div>
                    <div class="detail-item">
                        <label>Duration</label>
                        <span>
                            {{ $filter([request.startTime, request.endTime], 'duration') }}
                        </span>
                    </div> -->
                </div>

                <div class="detail-item">
                    <label>Request Location</label>
                    <a href="#">
                        {{ request.requestAddress.address }}
                    </a>
                </div>
            </div>

            <div v-else class="flow-root flex-1 overflow-auto px-4 sm:px-4">
                <ul class="-mb-8">
                    <timeline-event
                        v-for="(event, idx) in timeline"
                        :key="idx"
                        :event="event"
                        :idx="idx"
                        :isLast="idx !== timeline.length - 1"
                    />
                </ul>
            </div>
        </template>
    </DetailPopover>
</template>

<script>
import DetailPopover from '@/common/components/c-detail-popover';
import Map from '@/common/components/c-map';
import Tabs from '@/common/components/c-tabs';

import TimelineEvent from './request-details/f-timeline-event';
import { generateTimeline } from './request-details/timeline-generator';

export default {
    props: ['requestId'],

    components: {
        DetailPopover,
        Map,
        Tabs,
        TimelineEvent
    },

    data() {
        return {
            request: null,
            activeTab: 'Overview',
            timeline: []
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        close() {
            this.$emit('closed');
        },

        async load() {
            const response = await this.$http.get('/api/portal/requests/' + this.requestId);
            this.request = response.data;
            this.timeline = generateTimeline(this.request);
        }
    }
};
</script>

<style lang="scss" scoped>
.side-panel {
    @apply fixed inset-0 overflow-hidden;
}
.overlay {
    @apply absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity;
}
.close-button {
    @apply bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 h-8 w-8 flex items-center justify-center;
}

#map {
    @apply w-full bg-gray-100;
    min-height: 300px;
}
.overview {
    @apply flex flex-col px-6 pt-2;

    .row {
        @apply flex justify-between;

        .detail-item:last-child {
            @apply text-right;
        }
    }

    .detail-item {
        @apply flex flex-col mb-4;

        a {
            @apply text-blue-800 hover:underline;
        }
    }

    label {
        @apply font-semibold text-gray-400;
    }
}
</style>
