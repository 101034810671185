export { computePermissions };

function computePermissions(session) {
    if (!session) return null;

    const { role, accessLevel } = session;
    return {
        requests: true,
        'requests.zone': role === 'sysadmin',
        'requests.client': accessLevel === 'sysadmin' || role !== 'client' || accessLevel === 'manager',
        'requests.officer': accessLevel === 'sysadmin' || role !== 'officer' || accessLevel === 'manager',
        'requests.agency': role === 'sysadmin',
        prescheduled: accessLevel === 'sysadmin',
        agencies: accessLevel === 'sysadmin',
        officers: accessLevel === 'sysadmin' || (role === 'officer' && accessLevel === 'manager'),
        'officers.agencySelect': accessLevel === 'sysadmin',
        clients: accessLevel === 'sysadmin' || (role === 'client' && accessLevel === 'manager'),
        zones: accessLevel === 'sysadmin',
        membership: accessLevel === 'sysadmin',
        promoCodes: accessLevel === 'sysadmin'
    };
}
