<template>
    <div class="flex-1">
        <div class="flex-1 flex flex-row justify-between mb-4 px-1">
            <div>
                <div class="text-gray-500 text-xs">Expected Officers</div>
                <div>{{ sr.quoteOfficerCount }}</div>
            </div>

            <div>
                <div class="text-gray-500 text-xs">Officers Accepted</div>
                <div class="text-right">{{ sr.quoteOfficerCount - sr.dispatchPendingCount }}</div>
            </div>
        </div>

        <table-component hoverable :columns="columns" :data="sr.officerResponses" clickable>
            <template v-slot:empty>No officers have accepted this request yet.</template>
            <template v-if="sr.officerResponses" v-slot:rows>
                <tr v-for="response in sr.officerResponses" :key="response.id" @click="viewOfficer(response.officer)">
                    <td class="avatar-cell">
                        <Avatar :person="response.officer" />
                        {{ response.officer.firstName }}
                        {{ response.officer.lastName }}
                    </td>
                    <td class="status-cell">
                        <span :class="`status-text ${getStatusColor(response.response ?? 'pending')}`">
                            {{ (response.response ?? 'pending').ucfirst() }}
                        </span>
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import Avatar from '@/common/components/c-avatar';
import TableComponent from '@/common/components/c-table';
import OfficerModal from '@/views/portal/officers/m-edit-officer';

const STATUS_MAP = {
    pending: 'yellow',
    accepted: 'blue',
    'checked-in': 'green',
    'checked-out': 'red'
};

export default {
    store: ['session'],
    props: ['sr', 'modal'],

    components: { TableComponent, Avatar },

    data() {
        return {};
    },

    computed: {
        columns() {
            return ['Name', 'Status'];
        }
    },

    methods: {
        getStatusColor(status) {
            return STATUS_MAP[status];
        },

        viewOfficer(officer) {
            this.$modal(OfficerModal, { officer });
        }
    }
};
</script>
