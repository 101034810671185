<template>
    <div id="signup">
        <loader v-if="isLoading" />
        <form v-show="!isLoading" ref="signupInner" @submit.prevent="submit">
            <div v-if="isSubmitted">
                Thanks! Your gift card will be sent to {{ gc.recipientFirstName }} {{ gc.recipientLastName }}
                {{ sendDateResult }}.
            </div>
            <template v-else>
                <div v-if="loadError" class="error load-error">{{ loadError }}</div>

                <section>
                    <h1>1. Choose a Plan</h1>
                    <div class="form-group">
                        <label v-for="plan in plans" :key="plan.id" class="radio">
                            <input type="radio" v-model="selectedPlanId" :value="plan.id" />
                            <div>
                                <span class="label">{{ plan.title }}</span>
                                <span v-if="plan.duration === 'recurring'" class="description">
                                    {{ $filter(plan.priceYearly, 'usCurrency', 'shortMoney') }}/year
                                </span>
                                <span v-else class="description">
                                    {{ $filter(plan.priceIndefinite, 'usCurrency', 'shortMoney') }} one-time
                                </span>
                            </div>
                        </label>
                        <label class="radio">
                            <input type="radio" v-model="selectedPlanId" value="amount" />
                            <div>
                                <span class="label">Gift a dollar amount</span>
                            </div>
                        </label>
                    </div>
                </section>

                <section v-if="selectedPlanId === 'amount'">
                    <h1>2. Enter an Amount</h1>
                    <div class="form-group">
                        <input v-model="selectedAmount" type="number" v-autofocus required />
                    </div>
                </section>
                <section v-else-if="selectedPlan && selectedPlan.duration === 'recurring'">
                    <h1>2. Choose a Duration</h1>
                    <div class="form-group">
                        <label class="radio" v-for="duration in durations" :key="duration.years">
                            <input v-model="selectedDuration" type="radio" name="duration" :value="duration.years" />
                            <div>
                                <span class="label">{{
                                    duration.years === 1 ? '1 Year' : `${duration.years} Years`
                                }}</span>
                                <span class="description">
                                    {{ $filter(duration.price, 'usCurrency', 'shortMoney') }}
                                </span>
                            </div>
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ optionalStepOffset + 2 }}. Your Information</h1>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">First Name</span>
                            <input v-model="gc.firstName" type="text" required autocomplete="given-name" />
                        </label>

                        <label class="input">
                            <span class="label">Last Name</span>
                            <input v-model="gc.lastName" type="text" required autocomplete="family-name" />
                        </label>
                    </div>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">Email</span>
                            <input v-model="gc.email" type="email" required autocomplete="email" />
                        </label>

                        <label class="input">
                            <span class="label">Zip Code</span>
                            <input v-model="gc.zipCode" type="text" required autocomplete="postal-code" />
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ optionalStepOffset + 3 }}. Recipient Information</h1>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">First Name</span>
                            <input v-model="gc.recipientFirstName" type="text" required />
                        </label>

                        <label class="input">
                            <span class="label">Last Name</span>
                            <input v-model="gc.recipientLastName" type="text" required />
                        </label>
                    </div>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">Email</span>
                            <input v-model="gc.recipientEmail" type="email" required />
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ optionalStepOffset + 4 }}. Personalize</h1>

                    <div class="form-group">
                        <label class="input">
                            <textarea
                                v-model="gc.recipientMessage"
                                placeholder="Enter a personalized message (optional)"
                            />
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ optionalStepOffset + 5 }}. Delivery</h1>

                    <div class="form-group">
                        <label class="input">
                            <span class="label">When would you like this delivered?</span>
                            <input v-model="gc.sendOn" type="date" required />
                        </label>
                    </div>
                </section>

                <section>
                    <h1>{{ optionalStepOffset + 6 }}. Payment</h1>
                    <div class="form-group">
                        <label class="input">
                            <div class="stripe-input">
                                <div ref="cardElement" />
                            </div>
                        </label>
                    </div>
                </section>

                <div v-if="error" class="error">{{ error }}</div>

                <Loader v-if="isSubmitting" class="no-center"></Loader>
                <section v-else class="submit">
                    <button type="submit">Submit</button>
                </section>
            </template>
        </form>
    </div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js';
import { format } from 'date-fns';

import Loader from '../../common/components/c-loader';

export default {
    components: { Loader },

    data() {
        return {
            isLoading: true,
            loadError: null,
            selectedPlanId: null,
            selectedDuration: 1,
            selectedAmount: 100,
            gc: {
                firstName: null,
                lastName: null,
                email: null,
                zipCode: null,
                recipientFirstName: null,
                recipientLastName: null,
                recipientEmail: null,
                recipientMessage: null,
                sendOn: format(new Date(), 'yyyy-MM-dd')
            },
            plans: [],
            error: null,
            isSubmitting: false,
            isSubmitted: false,
            sendDateResult: null
        };
    },

    computed: {
        optionalStepOffset() {
            return this.selectedPlan?.duration === 'recurring' ? 1 : 0;
        },

        selectedPlan() {
            return this.plans.find(plan => plan.id === this.selectedPlanId);
        },

        durations() {
            return Array(3)
                .fill(0)
                .map((_, i) => ({
                    years: i + 1,
                    price: (i + 1) * this.selectedPlan.priceYearly
                }));
        }
    },

    async mounted() {
        await this.setupStripe();
        await this.load();
        this.preselectPlan();

        this.ready = true;
        window.addEventListener('resize', this.resizeHandler);
        this.resizeHandler();
    },

    beforeUnmount() {
        window.removeEventListener('resize', this.resizeHandler);
    },

    methods: {
        resizeHandler() {
            window.parent.postMessage(`contentHeight:${this.$refs.signupInner.offsetHeight}`, '*');
        },

        async load() {
            try {
                this.isLoading = true;
                this.loadError = null;

                const response = await this.$http.get('/api/embed/plans');
                this.plans = response.data.filter(p => p.duration !== 'indefinite' || p.priceIndefinite !== 0);
            } catch (err) {
                if (err.code === 'USERERR') {
                    this.loadError = err.message;
                } else {
                    throw err;
                }
            } finally {
                this.isLoading = false;
            }
        },

        setupStripe() {
            // eslint-disable-next-line no-async-promise-executor
            return new Promise(async resolve => {
                const stripe = await loadStripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
                const elements = stripe.elements({
                    fonts: [
                        {
                            cssSrc: 'https://fonts.googleapis.com/css?family=Inter'
                        }
                    ]
                });
                const cardElement = elements.create('card', {
                    style: {
                        base: {
                            fontFamily: 'Inter'
                        }
                    }
                });
                cardElement.mount(this.$refs.cardElement);
                cardElement.once('ready', resolve);

                this._stripe = stripe;
                this._cardElement = cardElement;
            });
        },

        preselectPlan() {
            if (this.$route.query.planId) {
                this.selectedPlanId = parseInt(this.$route.query.planId);
            } else if (this.$route.query.planType) {
                this.selectedPlanId = this.plans.find(plan => plan.type === this.$route.query.planType)?.id;
            }
            if (!this.selectedPlanId || !this.plans.find(plan => plan.id === this.selectedPlanId)) {
                this.selectedPlanId = this.plans[0].id;
            }
        },

        async submit() {
            if (this.isSubmitting) return;
            this.isSubmitting = true;
            this.error = null;

            try {
                if (this.gc.zipCode.length < 5 || /[^\d]/.test(this.gc.zipCode))
                    this.$throwUserError('Please enter a valid 5-digit ZIP code.');

                const stripePaymentMethodId = await this.getStripePaymentMethodId();

                const response = await this.$http.post('/api/embed/gift-cards', {
                    stripePaymentMethodId,
                    type: this.selectedPlanId === 'amount' ? 'amount' : 'membership',
                    amount: this.selectedAmount,
                    planId: this.selectedPlan?.id,
                    durationYears: this.selectedDuration,
                    firstName: this.gc.firstName,
                    lastName: this.gc.lastName,
                    email: this.gc.email,
                    zip: this.gc.zipCode,
                    recipientFirstName: this.gc.recipientFirstName,
                    recipientLastName: this.gc.recipientLastName,
                    recipientEmail: this.gc.recipientEmail,
                    recipientMessage: this.gc.recipientMessage,
                    sendOn: this.gc.sendOn
                });

                if (!response.data.ok) {
                    throw new Error('unexpected response');
                }

                this.isSubmitted = true;
                this.sendDateResult = response.data.sendDate;
                setTimeout(() => this.resizeHandler(), 250);

                window.parent.postMessage(`purchaseComplete`, '*');
            } catch (err) {
                if (err.code === 'USERERR') {
                    this.error = err.message;
                } else {
                    this.$reportError(err);
                    this.error = `Uh oh! An application error was encountered while processing your request. Please try again. If the problem persists, please contact info@gosecur.com. -- ${String(
                        err
                    )}`;
                }
            }

            this.isSubmitting = false;
        },

        async getStripePaymentMethodId() {
            const stripeResult = await this._stripe.createPaymentMethod({
                type: 'card',
                card: this._cardElement
            });
            if (stripeResult.error) {
                this.$throwUserError(stripeResult.error.message);
            }
            return stripeResult.paymentMethod.id;
        }
    }
};
</script>

<style lang="scss">
#signup {
    @apply flex-1;

    form {
        @apply bg-white px-4 sm:px-12 md:px-24 py-12;

        section {
            &:not(:first-of-type) {
                @apply mt-12;
            }

            &:last-child {
                @apply mb-12;
            }

            h1 {
                @apply text-2xl font-semibold;
            }

            .form-group {
                @apply flex-1 flex flex-wrap gap-3 ml-8 pt-4;

                .radio {
                    @apply flex-1 h-32 min-w-[220px] max-w-[280px];

                    div {
                        @apply flex-1 h-full flex flex-col items-center justify-center p-6 bg-neutral-200 duration-150 ring-0 ring-[#4361EE] cursor-pointer ease-in-out;

                        .label {
                            @apply text-xl font-bold;
                        }

                        .description {
                            @apply text-xs;
                        }

                        .detail {
                            @apply text-xs mt-2;
                        }
                    }

                    input[type='radio'] {
                        @apply appearance-none hidden;
                    }

                    input[type='radio']:checked + div {
                        @apply bg-white ring-[3px] ring-[#4361EE];
                    }
                }

                .input {
                    @apply flex-1 flex flex-col max-w-md;

                    .label {
                        @apply text-xs;
                    }

                    input,
                    textarea,
                    .stripe-input {
                        @apply border-none bg-neutral-200 mt-2 duration-75 ease-in-out;

                        // unfortunate hack for removing autofill styling
                        &:-webkit-autofill {
                            -webkit-box-shadow: 0 0 0 30px rgb(229, 229, 229) inset, 0 0 0 0px rgb(0, 0, 0);

                            &:focus {
                                -webkit-box-shadow: 0 0 0 30px rgb(229, 229, 229) inset, 0 0 0 2px rgb(255, 255, 255),
                                    0 0 0 4px rgb(0, 0, 0);
                            }
                        }

                        &:focus {
                            @apply border-transparent ring-2 ring-black ring-offset-2;
                        }
                    }

                    .stripe-input {
                        @apply p-3;
                    }
                }
            }

            .stripe-form {
                // max-w is semi-arbitrary value based on input max-w + margin. so payment fields are same width as inputs.
                @apply ml-8 mt-4 max-w-[908px];
            }
        }

        .error {
            @apply ml-8 text-red-700;

            &.load-error {
                @apply mb-6;
            }

            &:not(.load-error) {
                @apply mt-6;
            }
        }

        section.submit {
            @apply ml-8;
        }

        button {
            @apply relative text-black bg-white font-bold text-lg py-1 px-20 border-[3px] border-black overflow-hidden z-10 cursor-pointer duration-75 ease-in-out;

            &:hover {
                @apply text-white;
            }

            &:after {
                @apply absolute w-full h-full bg-black left-0 top-full duration-75 pointer-events-none -z-10 ease-in-out;
                content: '';
            }

            &:hover:after {
                @apply top-0;
            }
        }
    }
}
</style>
