<template>
    <div id="zones" class="flex-1 flex flex-col">
        <div class="top-filters">
            <h1 class="text-2xl font-semibold text-gray-900">
                {{ $route.name }}
            </h1>
            <Switcher v-model="view" :options="['list', 'map']" />

            <div class="filters">
                <button type="button" class="blue ml-4" @click="editZone()">
                    <i class="far fa-plus text-lg" />
                </button>
            </div>
        </div>

        <table-component
            v-if="view === 'list'"
            clickable
            hoverable
            :columns="['Active', 'Name', 'Coverage', 'Price', 'Tax Rate', 'Duration', 'Min Duration']"
            :data="data"
            :isLoading="isLoading"
        >
            <template v-slot:rows>
                <tr v-for="(zone, i) in data" :key="i.toString()" @click="editZone(zone)">
                    <td class="indicator-cell">
                        <i class="fa fa-circle" :class="[zone.isActive && 'active']" />
                    </td>
                    <td>{{ zone.title }}</td>
                    <td v-if="zone.squareMiles">{{ zone.squareMiles }} sq mi</td>
                    <td v-else>-</td>
                    <td>${{ zone.pricePerPeriod }}</td>
                    <td>{{ zone.taxRate }}%</td>
                    <td>{{ zone.periodMinutes }} mins</td>
                    <td>{{ zone.periodMinutes * zone.minimumPeriodCount }} mins</td>
                </tr>
            </template>
        </table-component>

        <Map
            v-if="view === 'map'"
            class="map"
            :status="isLoading && 'Loading...'"
            :feature="zoneFeatureCollection"
            auto-feature-zoom
            @feature-clicked="id => editZone(data.find(zone => zone.id === id))"
        />
    </div>
</template>

<script>
import Map from '@/common/components/c-map';
import Switcher from '@/common/components/c-switcher';
import TableComponent from '@/common/components/c-table';

import EditModal from './zones/m-edit-zone';

export default {
    components: { Map, Switcher, TableComponent },

    computed: {
        zoneFeatureCollection() {
            return {
                type: 'FeatureCollection',
                features:
                    this.data
                        ?.filter(zone => zone.geoFeature)
                        .map(zone => ({
                            ...zone.geoFeature,
                            properties: { id: zone.id, title: zone.title }
                        })) ?? []
            };
        }
    },

    data() {
        return {
            view: 'list',
            isLoading: true,
            data: null
        };
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            this.isLoading = true;
            this.data = null;

            const response = await this.$http.get('/api/portal/zones');
            this.data = response.data;

            this.isLoading = false;
        },

        async editZone(zone) {
            const result = await this.$modal(EditModal, { zone });
            if (result === undefined) return;

            this.load();
        }
    }
};
</script>

<style lang="scss" scoped>
.indicator-cell i {
    @apply text-gray-400;

    &.active {
        @apply text-green-400;
    }
}

.map {
    @apply flex-1;
}
</style>
