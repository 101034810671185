<template>
    <div id="submembers">
        <table-component
            hoverable
            actionCell
            :columns="['Name', 'Email', 'Access']"
            :data="filteredData"
            :isLoading="isLoading"
        >
            <template v-slot:empty> This client has no submembers. </template>
            <template v-slot:rows>
                <tr v-for="(submember, i) in filteredData" :key="i.toString()" @dblclick="editClient(submember)">
                    <td>
                        {{ submember.firstName }}
                        {{ submember.lastName }}
                    </td>
                    <td>{{ submember.email }}</td>
                    <td class="status-cell">
                        <span :class="['status-text', submember.accessLevel === 'standard' ? 'gray' : 'blue']">{{
                            submember.accessLevel?.ucfirst()
                        }}</span>
                    </td>
                    <td class="action-cell">
                        <a href="#" class="action-link" @click.prevent="editClient(submember)"> Edit </a>
                    </td>
                </tr>
            </template>
        </table-component>
    </div>
</template>

<script>
import TableComponent from '@/common/components/c-table';

export default {
    emits: ['openClient'],
    props: ['client'],

    components: {
        TableComponent
    },

    data() {
        return {
            isLoading: true,
            data: null
        };
    },

    computed: {
        filteredData() {
            return this.data?.filter(submember => submember.id !== this.client.id);
        }
    },

    mounted() {
        this.load();
    },

    methods: {
        async load() {
            const response = await this.$http.get('/api/portal/clients', {
                params: {
                    masterId: this.client.id
                }
            });
            this.data = response.data;

            this.isLoading = false;
        },

        editClient(submember) {
            this.$emit('openClient', submember);
        }
    }
};
</script>

<style lang="scss" scoped>
#submembers {
    @apply w-full pt-2;

    label {
        @apply block text-sm font-medium text-gray-500;
    }

    table {
        &.hoverable {
            tbody {
                tr:not(.loading):hover {
                    //   @apply cursor-pointer;
                }
            }
        }
    }
}
</style>
